import React, { useState, useEffect } from "react"
import { Prompt } from "react-router-dom"
import PropTypes from "prop-types"

import styles from "./HigherLearningGroup.module.scss"
import HigherLearningItem from "./HigherLearningItem/HigherLearningItem"
import Button from "../../../Button/Button"
import { objectDeepCopy, objectComparison } from "../../../../helpers"
import ProfileTableItem from "../../ProfileTableItem"
import Popup from "../../../Popup/Popup"
import { messages } from "../../../../helpers/constants"

const HigherLearningGroup = ({ institutes, onUpdateSchools, anotherUser }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentInstitutes, setCurrentInstitutes] = useState(institutes)
  const [isPopupVisible, setPopupVisible] = useState(false)
  const [isMaxErrorVisible, setMaxErrorVisible] = useState(false)
  const [errors, setErrors] = useState({})
  const [isAbleToDelete, setIsAbleToDelete] = useState(false)

  useEffect(() => {
    setCurrentInstitutes(institutes)
    institutes.length && setIsAbleToDelete(true)
  }, [institutes])

  useEffect(() => {
    currentInstitutes.length
      ? setIsAbleToDelete(true)
      : setIsAbleToDelete(false)
  }, [currentInstitutes.length])

  const addInstitution = () => {
    const lastId = currentInstitutes[currentInstitutes.length - 1]?.id || 0
    const newInstitute = {
      id: lastId + 1,
      name: "",
      degree: "",
      start: {
        month: {},
        year: {},
      },
      finish: {
        month: {},
        year: {},
      },
    }

    if (currentInstitutes.length < 5) {
      setCurrentInstitutes([...currentInstitutes, newInstitute])
      setIsEditing(true)
    } else {
      setMaxErrorVisible(true)
    }
  }

  const handleError = (newErrors, id) => {
    setErrors({ ...errors, [id]: newErrors.validation })
  }

  const handleDeleteSchool = (id) => {
    let schoolsCopy = objectDeepCopy(currentInstitutes)
    schoolsCopy = schoolsCopy.filter((s, i) => s.id !== id)
    const errorsCopy = { ...errors }
    delete errorsCopy[id]
    setCurrentInstitutes(schoolsCopy)
    setErrors(errorsCopy)
    if (currentInstitutes.length === 0) {
      setIsEditing(false)
    }
  }

  const checkHasDataToShow = () => currentInstitutes.length > 0

  const cancelChanges = () => {
    setCurrentInstitutes(institutes || [])
    setIsEditing(false)
    setPopupVisible(false)
    setMaxErrorVisible(false)
    setErrors({})
  }

  const handleCancelButton = () => {
    if (objectComparison(institutes, currentInstitutes)) {
      cancelChanges()
    } else {
      setPopupVisible(true)
    }
  }

  const handleSave = () => {
    const institutes = currentInstitutes.map((i) => ({
      ...i,
      start: i.start.month.id.concat(i.start.year.value),
      finish: i.finish.month.id.concat(i.finish.year.value),
    }))
    onUpdateSchools({ schools: institutes })
    setIsEditing(false)
  }

  const handleChangeInstitute = (id, value) => {
    const institutesCopy = objectDeepCopy(currentInstitutes)
    const index = institutesCopy.findIndex((s) => s.id === id)
    institutesCopy[index] = value
    setCurrentInstitutes(institutesCopy)
  }

  const handleStartEdit = () => {
    if (currentInstitutes.length === 0) {
      addInstitution()
    } else {
      setIsEditing(true)
    }
  }

  return (
    <>
      <Prompt
        when={!objectComparison(institutes, currentInstitutes)}
        message={messages.CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE}
      />
      {isPopupVisible && (
        <Popup
          dialog
          isCrossIcon
          closeAction={() => setPopupVisible(false)}
          subtitle="Are you sure you want to cancel your changes?"
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => {
                setPopupVisible(false)
              }}
            >
              No
            </Button>
          }
          button={
            <Button buttonStyle="black" onClick={cancelChanges}>
              Yes
            </Button>
          }
        />
      )}

      <ProfileTableItem
        title="Higher Learning"
        description="The more you share about your education the better your profile will standout."
        linkText="+ Add higher learning information"
        hasData={checkHasDataToShow()}
        isActive={isEditing}
        onClick={() => {
          handleStartEdit()
        }}
        anotherUser={anotherUser}
      />
      {isEditing && (
        <p className={styles.hintMessage}>
          You have to fill all fields to save your changes
        </p>
      )}
      {currentInstitutes.map((institute, index) => (
        <HigherLearningItem
          key={institute.id}
          data={institute}
          isEditing={isEditing}
          handleChangeInstitute={handleChangeInstitute}
          handleError={handleError}
          isLast={currentInstitutes.length - 1 === index}
          addInstitution={addInstitution}
          handleDeleteSchool={handleDeleteSchool}
          id={institute.id}
          isAbleToDelete={isAbleToDelete}
        />
      ))}
      {isEditing && (
        <>
          {isMaxErrorVisible && (
            <p className={styles.errorMessage}>
              Sorry, you can't add more than 5 schools.
            </p>
          )}
          <div className={styles.btnsWrapper}>
            <Button buttonStyle="outlined" onClick={handleCancelButton}>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              disabled={Object.values(errors).some((e) => e === false)}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  )
}

HigherLearningGroup.propTypes = {
  institutes: PropTypes.array,
}

HigherLearningGroup.defaultProps = {
  institutes: [],
}

export default HigherLearningGroup
