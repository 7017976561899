import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams, useHistory, Redirect } from "react-router"
import classNames from "classnames"
import Box from "@material-ui/core/Box"

import styles from "./SelectedFreelancerContainer.module.scss"
import UserInfo from "../../components/UserInfo/UserInfo"
import userServices from "../../services/userServices"
import { paths } from "../../routes/paths"
import services from "../../services"
import Breadcrumbs from "../../components/elements/Breadcrumbs"
import useBreadcrumbs from "./useBreadcrumbs"
import Alert from "@material-ui/lab/Alert"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { ADMIN } from "../../helpers/userRoles"
import FreelancerProfileTable from "../../components/ProfileTable/FreelancerProfileTable";

const SelectedUserProfileContainer = () => {
    const [data, setData] = useState({})
    const userInfo = useSelector((state) => state.user)
    const breadcurmbsPaths = useBreadcrumbs(data)
    const [reportAlertVisible, setReportAlertVisible] = useState(false)
    const { id } = useParams()
    const history = useHistory()

    const onBlock = (status) => {
        services.reportServices
            .blockUser({ user_uuid: data.uuid, block: status })
            .then(() => {
                setData({ ...data, blocked: status })
            })
            .catch(console.log)
    }

    const onReport = ({ title, description }) => {
        return services.reportServices
            .reportUser({
                title,
                description,
                reported_id: data.uuid,
                reported_type: "user",
            })
            .then((result) => {
                setReportAlertVisible(true)
                setData({ ...data, reported: true })
                setTimeout(() => setReportAlertVisible(false), 5000)
                return result
            })
    }

    useEffect(() => {
        userServices
            .getFreelanceProfile(id)
            .then((result) => {
                setData({ ...result.data})
            })
            .catch(() => history.replace({ pathname: "/not-found", isActive: true }))
    }, [history, id])

    const onOpenChat = async () => {
        const uuid =
            data.uuid ||
            (await services.freelancerServices
                .getUuidByExternalId(data.external_id)
                .then(({ data }) => data.uuid))

        if (data.chat) {
            history.push(paths.chats(data.chat))
        } else {
            services.chatServices
                .createChatRoom({ type: "freelancer", uuid: uuid })
                .then(({ data }) => {
                    history.push(paths.chats(data.uuid))
                })
        }
        // if (!data.chat) {
        //     services.chatServices
        //         .createChatRoom({ type: "user", uuid: id })
        //         .then((result) => history.push(paths.chats(result.data.uuid)))
        //         .catch((error) => console.log(error))
        // } else {
        //     history.push(paths.chats(data.chat))
        // }
    }

    if (userInfo.uuid === id) {
        return <Redirect to={paths.individualProfile} />
    }

    if (data?.blocked && userInfo.role !== ADMIN) {
        return <Redirect to={paths.notFound} />
    }
    return (
        <div className={classNames("container", "fullWidth", styles.profileWrap)}>
            {reportAlertVisible && (
                <Alert
                    variant="filled"
                    severity="info"
                    className={styles.alert}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setReportAlertVisible(false)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    Your report was successfully sent.
                </Alert>
            )}

            {userInfo.role !== ADMIN && (
                <Breadcrumbs classes={styles.breadcrumbs} chain={breadcurmbsPaths} />
            )}

            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                style={{ width: "100%" }}
            >
                <div className={styles.profileLeftColumn}>
                    <div className={styles.profileInfo}>
                        <UserInfo
                            describeMe={data["describe_me"]}
                            title={data["title"]}
                            activity={data["activity"]}
                            gender={data["gender"]}
                            race={data["race"]}
                            fullName={data["name"]}
                            image={data.photo}
                            anotherUser
                            onOpenChat={onOpenChat}
                            onReport={onReport}
                            reported={data.reported}
                            userRole={userInfo.role}
                            onBlock={onBlock}
                            isBlocked={data.blocked}
                            userType='freelancer'
                        />
                    </div>
                </div>
                <div className={styles.profileRightColumn}>
                    <FreelancerProfileTable anotherUser data={data} />
                </div>
            </Box>
        </div>
    )
}

export default SelectedUserProfileContainer
