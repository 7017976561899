import React, { useState, useEffect } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "./Videos.module.scss"
import VideoCard from "./VideoCard/VideoCard"
import VideoPlayerPopup from "./VideoPlayerPopup/VideoPlayerPopup"
import { Modal, withModal } from "../../context/modalContext"
import Button from "../Button/Button"
import { Link } from "react-router-dom"
import UploadMediaPopup from "./UploadMediaPopup/UploadMediaPopup"
import useUploadVideo from "./hooks/useUploadVideo"
import { source } from "../../services/axios"
import { paths } from "../../routes/paths"

const VideosPreviewer = ({
  className,
  videos,
  onAddVideo,
  onDeleteVideo,
  anotherUser,
  context: { toggleModal, closeModal },
  userId,
  userType,
}) => {
  const [selectedVideo, setSelectedVideo] = useState()
  const [deleteVideoUuid, setDeleteVideoUuid] = useState()
  const [visibleUploadPopup, setVisibleUploadPopup] = useState(false)
  const [newVideos, setNewVideos] = useState([])

  const {
    uploadedVideos,
    onUploadVideo,
    uploadedVideosUrl,
    handlePopupMediaDelete,
    clearUploadedVideos,
    loading,
    error,
    setError,
    setLoading,
    handleUploadMedia,
  } = useUploadVideo()

  useEffect(() => {
    if (videos.length === 12 && visibleUploadPopup) {
      setError("Sorry, you can`t add more than 12 videos.")
    }
  }, [videos, visibleUploadPopup])

  useEffect(() => {
    if (newVideos.length) {
      onAddVideo(newVideos)
      setNewVideos([])
    }
  }, [newVideos])

  useEffect(() => {
    if (!visibleUploadPopup) {
      setError()
    }
  }, [visibleUploadPopup])

  const uploadVideo = () => {
    handleUploadMedia().then((result) => {
      setNewVideos([...newVideos, ...result.data])
      setVisibleUploadPopup(false)
    })
  }

  if (anotherUser && videos.length === 0) return null

  return (
    <div className={classNames(styles.videosWrap, className)}>
      <Modal
        id="deleteVideo"
        dialog
        subtitle="Do you want to delete this video?"
        subtitleStyle={{ fontWeight: 500 }}
        button={
          <Button
            buttonStyle="black"
            width="97px"
            onClick={() => {
              onDeleteVideo(deleteVideoUuid)
              closeModal()
            }}
          >
            Yes, sure
          </Button>
        }
        acceptButton={
          <Button
            buttonStyle="outlinedBlack"
            width="64px"
            onClick={() => closeModal()}
          >
            No
          </Button>
        }
        closeAction={() => closeModal()}
        isCrossIcon={true}
      />

      {selectedVideo && (
        <VideoPlayerPopup
          video={selectedVideo}
          closeAction={() => {
            setSelectedVideo()
          }}
          deleteVideo={(videoUuid) => {
            onDeleteVideo(videoUuid)
            setSelectedVideo()
          }}
          anotherUser={anotherUser}
        />
      )}

      <UploadMediaPopup
        visible={visibleUploadPopup}
        uploadedMedias={uploadedVideos}
        galleryData={videos}
        error={error}
        title="Upload videos"
        acceptableFileTypes=".mp4, .m4v, .mov, .mpg, .mpeg"
        mediaType="video"
        loader={loading}
        handleUploadMedia={(e) => {
          onUploadVideo(videos, e)
        }}
        handleUpload={uploadVideo}
        handlePopupMediaDelete={handlePopupMediaDelete}
        handleCancel={() => {
          source.cancel()
          clearUploadedVideos()
          setVisibleUploadPopup(false)
          setLoading(0)
        }}
        anotherUser={anotherUser}
      />

      <div className={styles.topInfo}>
        <h5 className={styles.mainTitle}>
          <Link
            // to={anotherUser ? `/video-gallery/${userId}` : "/video-gallery"}
            to={
              anotherUser
                ? paths.videoGallery(userType, `/${userId}`)
                : paths.videoGallery("", "")
            }
          >
            Videos
          </Link>
        </h5>
        {anotherUser === false && videos?.length > 0 && (
          <p
            className="goldLink"
            onClick={() => {
              setVisibleUploadPopup(true)
            }}
          >
            Add +
          </p>
        )}
      </div>
      <div className={styles.videosWrapper}>
        {videos?.slice(0, 4).map((video) => (
          <VideoCard
            key={video.id}
            video={video}
            onOpen={(openedVideo) => {
              setSelectedVideo(openedVideo)
            }}
            deleteVideo={(videoUuid) => {
              setDeleteVideoUuid(videoUuid)
              toggleModal("deleteVideo")
            }}
            className={styles.videoCard}
            anotherUser={anotherUser}
          />
        ))}
        {!videos?.length > 0 && !anotherUser && (
          <VideoCard
            empty
            onAdd={() => {
              setVisibleUploadPopup(true)
            }}
            className={styles.emptyVideoCard}
            anotherUser={anotherUser}
          />
        )}
      </div>
    </div>
  )
}

VideosPreviewer.propTypes = {
  videos: PropTypes.array.isRequired,
  onDeleteVideo: PropTypes.func.isRequired,
  userType: PropTypes.oneOf(["individuals", "companies"]).isRequired,
}

VideosPreviewer.defaultProps = {
  videos: [],
}

export default withModal(VideosPreviewer)
