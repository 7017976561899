import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import classNames from "classnames"

import styles from "./index.module.scss"
import LabelBadge from "../elements/LabelBadge"
import Button from "../Button/Button"
import ThreeDotsMenu from "../ThreeDotsMenu/ThreeDotsMenu"
import Popup from "../../components/Popup/Popup"
import Share from "../Share"
import { ReactComponent as ShareIcon } from "../../images/icons/shareIcon.svg"

const ResourcePageHeader = ({
  title,
  subTitle,
  badgeText,
  badgeBgColor,
  bgImg,
  company,
  onDelete,
  onAttend,
  isAttendee,
  uuid,
}) => {
  const userInfo = useSelector((state) => state.user)
  const history = useHistory()
  const [refusePopupOpened, setRefusePopupOpened] = React.useState(false)

  return (
    <React.Fragment>
      {refusePopupOpened && (
        <Popup
          dialog
          subtitle="Are you sure you want to cancel your attendance?"
          button={
            <Button
              buttonStyle="black"
              onClick={() => {
                onAttend(false)
                setRefusePopupOpened(false)
              }}
            >
              Yes
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => {
                setRefusePopupOpened(false)
              }}
            >
              No
            </Button>
          }
          closeAction={() => {
            setRefusePopupOpened(false)
          }}
          isCrossIcon={true}
        />
      )}

      <div
        className={styles.resourceHeaderWrap}
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className={styles.resourceBadge}>
          <LabelBadge text={badgeText} bgColor={badgeBgColor} />
        </div>

        <div
          className={classNames(
            {
              [styles.resourceMenuNotEditable]:
                userInfo?.company?.uuid !== company.uuid,
            },
            styles.resourceMenu,
          )}
        >
          {userInfo?.company?.uuid === company.uuid && (
            <div className={styles.resourceMenuItem}>
              <ThreeDotsMenu
                actions={[
                  {
                    name: "Edit",
                    click: () => history.push(`/resources-edit?id=${uuid}`),
                  },
                  {
                    name: "Delete",
                    click: onDelete,
                  },
                ]}
              />
            </div>
          )}
          <div className={styles.resourceMenuItem}>
            <Share
              title={title}
              url={window.location.href.replace(":3000", ".com")}
            >
              <ShareIcon />
            </Share>
          </div>
        </div>

        <div className={styles.resourceHeaderContent}>
          <div className={styles.resourceTitle}>{title}</div>

          <div className={styles.resourceSubTitle}>{subTitle}</div>

          {userInfo.company.type !== "company_provider" && (
            <div className={styles.resourceAttendBtn}>
              {isAttendee ? (
                <React.Fragment>
                  <Button
                    onClick={() => setRefusePopupOpened(true)}
                    buttonStyle="black"
                    style={{ marginTop: "32px" }}
                  >
                    Decline
                  </Button>
                  <div className={styles.resourceRespondMsg}>
                    You have already responded
                  </div>
                </React.Fragment>
              ) : (
                <Button
                  onClick={() => onAttend(true)}
                  style={{ marginTop: "32px" }}
                >
                  Attend
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

ResourcePageHeader.propTypes = {
  badgeText: PropTypes.string.isRequired,
  badgeBgColor: PropTypes.string.isRequired,
  bgImg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  company: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func,
  onAttend: PropTypes.func,
  isAttendee: PropTypes.bool,
  uuid: PropTypes.string.isRequired,
}

ResourcePageHeader.defaultProps = {
  onAttend: () => console.log("Clicked Attend Button"),
  onDelete: () => console.log("Clicked Delete Button"),
  isAttendee: false,
}

export default ResourcePageHeader
