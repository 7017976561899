import React from "react"
import { MarketSelectorView } from "../../../../MarketSelectorView"
import { setCompanyMarket } from "../../useReducer/actionCreators"

const CompanyMarketTypeDropdown = ({ externalDispatch, selectedType }) => {
  return (
    <MarketSelectorView
      market={selectedType}
      onChange={(market) => externalDispatch(setCompanyMarket(market))}
    />
  )
}
export default CompanyMarketTypeDropdown
