export const options = [
  {
    id: "",
    value: "All",
  },
  {
    id: "black_african_american",
    value: "Black/African-American",
  },
  {
    id: "american_indian",
    value: "American Indian/Alaskan Native",
  },
  {
    id: "asian",
    value: "Asian",
  },
  {
    id: "native_hawaiian",
    value: "Native Hawaiian/Pacific Islander",
  },
  {
    id: "hispanic",
    value: "Hispanic/Latino",
  },
  {
    id: "white",
    value: "White",
  }
]
