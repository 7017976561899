import React from "react"
import Dropdown from "../../../../Dropdown/Dropdown"
import { options } from "./options"
import { setRaceType } from "../../useReducer/actionCreators"
import useOption from "./useOption"

const RaceTypeDropdown = ({ externalDispatch, selectedType }) => {
  const [option, setOption] = useOption(selectedType)
  return (
    <Dropdown
      label="Race"
      id="race_type_dropdown"
      options={options}
      value={option}
      onChange={(option) => {
        setOption(option)
        externalDispatch(setRaceType(option.id))
      }}
    />
  )
}
export default RaceTypeDropdown
