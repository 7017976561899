import React, { useState } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import styles from "./CompanyResourcesContainer.module.scss"
import Button from "../../components/Button/Button"
import PageLinedTitle from "../../components/elements/PageLinedTitle"
import TrendingTopics from "../../components/TrendingTopics"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"
import ResourceCard from "../../components/cards/ResourceCard/index.jsx"
import services from "../../services"
import Breadcrumbs from "../../components/elements/Breadcrumbs"
import NoDataPlaceholder from "../../components/elements/NoDataPlaceholder"
import Loading from "../../components/Loading/Loading"
import Pagination from "../../components/elements/Pagination"
import CompanyAddonTypePopup from "../../components/CompanyAddonTypePopup"
import { resourcesTypeCards } from "../../components/CompanyResources/helpers"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import { COMPANY_PROVIDER } from "../../helpers/userRoles"

const CompanyResourcesContainer = () => {
  const [state, setState] = React.useState({
    isLoading: false,
  })
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [perPage, setPerPage] = useState(12)
  const { width } = useWindowHeightWidth()

  const userType = useSelector((state) => state.user.company.type)
  const companyUuid = useSelector((state) => state.user.company.uuid)

  const isMyResources =
    userType === COMPANY_PROVIDER && companyUuid === params.uuid

  const currentPage = getCurrentPage(location)

  const handleClosePopup = () => {
    setVisiblePopup(false)
  }

  const handleNextClick = ({ type }) => {
    history.push(`/resources-edit?type=${type}`)
  }

  React.useEffect(() => {
    if (width > 767) {
      setPerPage(12)
    } else {
      setPerPage(4)
    }
  }, [width])

  React.useEffect(() => {
    setState({
      isLoading: true,
    })

    services.resourceServices
      .getCompanyResources(params.uuid, {
        page: currentPage,
        per: perPage,
      })
      .then((result) => {
        setState({
          ...result.data,
          isLoading: false,
        })
      })
  }, [currentPage, params.uuid, perPage])

  return (
    <div className={styles.pageWrapper}>
      {visiblePopup && (
        <CompanyAddonTypePopup
          width={1185}
          data={resourcesTypeCards}
          title={"Add Resource"}
          handleNextClick={handleNextClick}
          closeAction={handleClosePopup}
        />
      )}
      <div className="container">
        {state.company_name && (
          <Breadcrumbs
            chain={[
              {
                link: isMyResources
                  ? "/company-profile"
                  : `/company/${params.uuid}`,
                text: state.company_name,
              },
              {
                text: isMyResources
                  ? "My Resources"
                  : "Company/Organization Resources",
              },
            ]}
          />
        )}
      </div>

      <PageLinedTitle
        title={
          isMyResources ? "My Resources" : "Company/Organization Resources"
        }
      />

      <ContentWithSidebars
        topSidebar={() =>
          isMyResources && (
            <Button width="100%" onClick={() => setVisiblePopup(true)}>
              Add Resource
            </Button>
          )
        }
        bottomSidebar={() => <TrendingTopics />}
      >
        {state.isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {state.resources && state.resources.total_count ? (
              <React.Fragment>
                <div className={styles.resources}>
                  {state.resources.on_page.map((resource) => (
                    <ResourceCard key={resource.id} resource={resource} />
                  ))}
                </div>

                {getPageCount(state.resources.total_count, perPage) > 1 && (
                  <div style={{ padding: "20px", textAlign: "right" }}>
                    <Pagination
                      currentPageNumber={currentPage}
                      totalPageCount={getPageCount(
                        state.resources.total_count,
                        perPage,
                      )}
                      onSelect={(page) =>
                        history.push(`${location.pathname}?page=${page}`)
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <NoDataPlaceholder>No resources yet</NoDataPlaceholder>
            )}
          </React.Fragment>
        )}
      </ContentWithSidebars>
    </div>
  )
}

export default CompanyResourcesContainer

const getCurrentPage = (location) => {
  const searchParams = new URLSearchParams(location.search)
  return Number(searchParams.get("page")) || 1
}

const getPageCount = (totalCount, perPage) =>
  Math.ceil(totalCount / perPage) || 1
