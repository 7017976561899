import React from "react"
import classNames from "classnames"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import styles from "./LoginContainer.module.scss"
import SignInForm from "../../forms/SignInForm/SignInForm"
import services from "../../services"
import { userLogin } from "../../actions/user/userActions"
import { save } from "../../helpers/localStorage"
import { selectFormattedUser } from "../../reducers/selectors"
import { paths } from "../../routes/paths"

const LoginContainer = ({ userLogin }) => {
  const history = useHistory()

  const handleLogin = (params) =>
    new Promise((resolve, reject) =>
      services.userServices
        .signIn(params)
        .then((res) => {
          userLogin(res.data)
          save("currentUser", selectFormattedUser(res.data))

          resolve()
        })
        .catch((error) => {
          if (error.response?.data?.error === "Email not verified") {
            history.push(paths.emailVerifyResend(params.email))
          }

          reject(error)
        }),
    )

  return (
    <div className={styles.signInWrap}>
      <div
        fullWidth
        className={classNames("smallContainer", "fullWidth", styles.inlineWrap)}
      >
        <SignInForm handleLogin={handleLogin} />
      </div>
    </div>
  )
}

export default connect(null, {
  userLogin,
})(LoginContainer)
