export const options = [
  {
    id: 1,
    value: "College Student",
    databaseName: "college_students",
    subValue: [
      { id: 2, value: "Freshman", databaseName: "freshman" },
      { id: 3, value: "Sophomore", databaseName: "sophomores" },
      { id: 4, value: "Junior Senior", databaseName: "juniors_seniors" },
      { id: 5, value: "Graduate Student", databaseName: "graduate_students" },
    ],
  },
  {
    id: 6,
    value: "Professional",
    databaseName: "professionals",
    subValue: [
      { id: 7, value: "Freelancer", databaseName: "freelancers" },
      { id: 8, value: "Middle Manager", databaseName: "middle_managers" },
      { id: 9, value: "Recent Graduate", databaseName: "recent_graduates" },
      { id: 10, value: "Rising Executive", databaseName: "rising_executives" },
      { id: 11, value: "Senior Executive", databaseName: "senior_executives" },
    ],
  },
  {
    id: 12,
    value: "Business Owner",
    databaseName: "business_owners",
    subValue: [
      { id: 13, value: "Founder", databaseName: "founders" },
      { id: 14, value: "Mature", databaseName: "mature" },
      { id: 15, value: "Startup", databaseName: "startups" },
    ],
  },
  {
    id: 16,
    value: "Board Member",
    databaseName: "board_members",
    subValue: [
      {
        id: 17,
        value: "Board of Directors",
        databaseName: "board_of_directors",
      },
      { id: 18, value: "Diverse Company", databaseName: "diverse_company" },
      { id: 19, value: "Board Advisor", databaseName: "board_associates" },
    ],
  },
]

export const genderOptions = [
  {
    id: 1,
    value: "N/A",
    databaseName: "not_applicable_gender",
  },
  {
    id: 2,
    value: "Male",
    databaseName: "male",
  },
  {
    id: 3,
    value: "Female",
    databaseName: "female",
  },
]

export const raceOptions = [
  {
    id: 1,
    value: "N/A",
    databaseName: "not_applicable_race",
  },
  {
    id: 2,
    value: "Black/African-American",
    databaseName: "black_african_american",
  },
  {
    id: 3,
    value: "American Indian/Alaskan Native",
    databaseName: "american_indian",
  },
  {
    id: 4,
    value: "Asian",
    databaseName: "asian",
  },
  {
    id: 5,
    value: "Native Hawaiian/Pacific Islander",
    databaseName: "native_hawaiian",
  },
  {
    id: 6,
    value: "Hispanic/Latino",
    databaseName: "hispanic",
  },
  {
    id: 7,
    value: "White",
    databaseName: "white",
  },
]
