import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import TextArea from "../../../TextArea/TextArea"
import ProfileTableItem from "../../ProfileTableItem"
import Button from "../../../Button/Button"
import classNames from "classnames"
import styles from "./JobTitle.module.scss"
import validation from "./validation"
import { Prompt } from "react-router"
import ConfirmCancelPopup from "../../../Popup/ConfirmCancelPopup"
import { messages } from "../../../../helpers/constants"
import CopyToClipboard from "../../../CopyToClipboard"
import MultiSelectDropdown from "../../../MultiSelectDropdown"
import { jobTitleOptions } from "../../../CompanyUserInfo/options/jobTitleOptions"

const JobTitle = ({ data, update, anotherUser }) => {
  const maxLength = 64
  const placeholder = "Type your other job title"
  const [editedState, setEditedState] = useState({
    options: "",
    other: "",
  })
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const textArea = React.useRef(null)

  const parseData = (data) => {
    const options = data
        .split(", ")
        .map((dataValue) =>
            jobTitleOptions.find((title) => title.value === dataValue),
        )
        .filter((value) => value)
        .map((option) => option.databaseName)
        .join(",")
    const other = data.split(", ").filter(dataValue=>!jobTitleOptions.find((title) => title.value === dataValue))[0] || ""
    return {
      options,
      other,
    }
  }

  useEffect(() => {
    if (data) {
      setEditedState(parseData(data))
    }
    if (data === null) {
      setEditedState("")
    }
  }, [data])

  const onOptionsChange = (selectedOptions) => {
    if (!selectedOptions.includes("10")) {
      setErrorMessage("")
      setEditedState({ ...editedState, options: selectedOptions, other: "" })
    } else {
      setEditedState({ ...editedState, options: selectedOptions })
    }
    setHasChanges(true)
  }

  const onOtherChange = (e) => {
    setEditedState({ ...editedState, other: e.target.value })
    setHasChanges(true)
    setErrorMessage(validation(e.target.value))
  }

  const handleSave = () => {
    setHasChanges(false)
    setIsEditing(false)



    const jobTitlesOptions = editedState.options
      .split(",")
      .map(
        (jobTitle) =>
          jobTitleOptions?.find((option) => jobTitle === option.databaseName)
            ?.value,
      ).filter(option => option)
      .join(", ") || ""

      const jobTitle = editedState.other ?  jobTitlesOptions.concat(
              `, ${editedState.other}`,
          ) : jobTitlesOptions
    update({
      job_title: jobTitle})
  }

  const handleClosePopup = () => {
    setEditedState(data)
    setIsEditing(false)
    setErrorMessage("")
    setHasChanges(false)
    setIsPopupOpened(false)
  }

  const handleCancel = () => {
    if (hasChanges) {
      setIsPopupOpened(true)
    } else {
      setErrorMessage("")
      setEditedState(data)
      setIsEditing(false)
    }
  }

  return (
    <div className={styles.wrap}>
      <Prompt
        when={hasChanges}
        message={messages.CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE}
      />
      <ProfileTableItem
        title="Job title"
        onClick={() => {
          setIsEditing(true)
          setTimeout(() => {
            if (textArea.current) {
              textArea.current.selectionStart = editedState?.length + 1
              textArea.current.focus()
            }
          })
        }}
        hasData={!!(data && data.length)}
        isActive={isEditing}
        description="Tell the B2T community about your job position"
        linkText="+ Add information"
        anotherUser={anotherUser}
      />
      {isPopupOpened && hasChanges && (
        <ConfirmCancelPopup
          handleCancel={handleClosePopup}
          setIsPopupOpened={setIsPopupOpened}
        />
      )}
      <div
        className={classNames({
          [styles.wrapper]: data || isEditing,
          [styles.hiddenWrapper]: !data && !isEditing,
        })}
      >
        <div className={styles.content}>
          {isEditing ? (
            <>
              <MultiSelectDropdown
                wrapperStyles={{ height: "auto", marginBottom: 40 }}
                id="classification-select"
                name="classification"
                customPlaceholder="Select multiple options"
                fieldStyle="black"
                options={jobTitleOptions}
                selectedOptions={editedState.options}
                onBlur={() => {}}
                onChangeOption={onOptionsChange}
                label="Choose your job title:"
                // className={styles.editItem}
              />
              {editedState?.options?.includes("10") && (
                <TextArea
                  vertical
                  error={Boolean(errorMessage && errorMessage.length > 0)}
                  errorMessage={errorMessage}
                  value={editedState.other}
                  placeholder={placeholder}
                  onChange={onOtherChange}
                  currentLength={editedState.other?.trim().length}
                  maxLength={maxLength}
                  ref={textArea}
                />
              )}
            </>
          ) : (
            <div className={styles.contentText}>
              <CopyToClipboard text={(data && data.replace('Other, ','')) ?? ""} />
            </div>
          )}
        </div>
      </div>
      {isEditing && (
        <div className={styles.btnsWrap}>
          <Button
            onClick={handleCancel}
            className={styles.btn}
            buttonStyle="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className={styles.btn}
            buttonStyle="contained"
            disabled={Boolean(errorMessage && errorMessage.length > 0)}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

JobTitle.propTypes = {
  data: PropTypes.string,
}

JobTitle.defaultProps = {
  data: "",
}

export default JobTitle
