import React, { useEffect, useState } from "react"
import ProfileTableItem from "../../ProfileTableItem"
import styles from "./TopFive.module.scss"
import Button from "../../../Button/Button"
import classNames from "classnames"
import Popup from "../../../Popup/Popup"
import { objectDeepCopy } from "../../../../helpers"
import TopFiveItem from "./TopFiveItem/TopFiveItem"
import { Prompt } from "react-router-dom"
import { messages } from "../../../../helpers/constants"

const TopFive = ({ data, update, anotherUser }) => {
  const [editableState, setEditableState] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [inputsValidStatus, setInputsValidStatus] = useState([])
  const [isNeedToValidate, setIsNeedToValidate] = useState(0)

  const inputRef = React.useRef(null)

  useEffect(() => {
    if (data) {
      setEditableState(objectDeepCopy(data))
    }
  }, [data])

  const handleStartEdit = () => setIsEditing(true)

  const handleChange = (index, value) => {
    const idx = [...editableState].findIndex((link) => link.index === index)
    setEditableState(
      [...editableState],
      ([...editableState][idx].value = value.trim()),
    )
  }

  const getInputValidStatus = (index, value) => {
    setInputsValidStatus(
      Object.assign({ ...inputsValidStatus }, { [index]: value }),
    )
  }

  const checkHasDataToShow = () => {
    return data.some((item) => {
      return item.value
    })
  }

  const handleSave = () => {
    if (
      editableState.every((top) => top.value === "") &&
      checkAllInputsValid()
    ) {
      setIsEditing(false)
      setHasChanges(false)
      update({ top_five: editableState })
      setIsNeedToValidate(0)
    }
    // else if (editableState.some((top) => top.value === "")) {
    //   setIsNeedToValidate(isNeedToValidate + 1);
    // }
    if (
      editableState.some((top) => top.value.length > 0) &&
      checkAllInputsValid()
    ) {
      setIsEditing(false)
      setHasChanges(false)
      update({ top_five: editableState })
      setIsNeedToValidate(0)
    }
  }

  const onClosePopup = () => {
    handleCancelSaving()
  }

  const handleCancelSaving = () => {
    setHasChanges(false)
    setIsEditing(false)
    setIsPopupOpened(false)
    setEditableState(objectDeepCopy(data))
    setIsNeedToValidate(0)
  }

  const handleCancel = () => {
    if (hasChanges) {
      setIsPopupOpened(true)
      if (!editableState.every((top) => top.value === "")) {
        setIsNeedToValidate(isNeedToValidate + 1)
      }
    } else {
      setIsPopupOpened(false)
      setIsEditing(false)
      setIsNeedToValidate(0)
    }
  }
  const checkAllInputsValid = () => {
    return Object.values(inputsValidStatus).every((item) => item === true)
  }
  return (
    <div>
      <Prompt
        when={hasChanges}
        message={messages.CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE}
      />
      <ProfileTableItem
        title="My Top 5"
        description="Things that you love, it could be hobbies, movies, books or anything you like!"
        linkText="+ Add your top 5"
        hasData={checkHasDataToShow()}
        isActive={isEditing}
        onClick={() => {
          handleStartEdit()
        }}
        anotherUser={anotherUser}
      />
      {isPopupOpened && (
        <Popup
          dialog
          subtitle={"Are you sure you want to cancel your changes?"}
          button={
            <Button buttonStyle="black" onClick={onClosePopup}>
              Yes
            </Button>
          }
          acceptButton={
            <Button
              className={styles.cancelButtonPopUp}
              onClick={() => setIsPopupOpened(false)}
              buttonStyle="outlinedBlack"
            >
              No
            </Button>
          }
          closeAction={() => setIsPopupOpened(false)}
          isCrossIcon={true}
        />
      )}
      <div
        className={classNames({
          [styles.contentContainer]: checkHasDataToShow() || isEditing,
          [styles.contentContainerHidden]: !checkHasDataToShow() && !isEditing,
        })}
      >
        <div className={classNames(styles.content)}>
          {isEditing && (
            <div className={classNames("text16r", styles.descText)}>
              In this section, you can enter up to 5 any of your favorite
              things, such as movies, hobbies, books or any other things you
              want.
            </div>
          )}
          {data &&
            data.map((item, index) => (
              <TopFiveItem
                type="top5"
                key={item.index}
                index={item.index}
                showData={!isEditing}
                onChange={handleChange}
                placeholder={`My top ${index + 1}`}
                setHasChanges={setHasChanges}
                getInputValidStatus={getInputValidStatus}
                propValue={item.value}
                isNeedToValidate={isNeedToValidate}
                autoFocus={index === 0 ? inputRef : null}
              />
            ))}
        </div>
      </div>
      {isEditing && (
        <div className={styles.btnsWrap}>
          <Button
            className={styles.btn}
            buttonStyle="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            buttonStyle="contained"
            className={styles.btn}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

TopFive.defaultProps = {
  data: [
    {
      index: 1,
      value: "",
    },
    {
      index: 2,
      value: "",
    },
    {
      index: 3,
      value: "",
    },
    {
      index: 4,
      value: "",
    },
    {
      index: 5,
      value: "",
    },
  ],
}

export default TopFive
