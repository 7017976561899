import { useReducer, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import { reducer } from "./reducer"
import { setSearchParams, setRequestResult } from "./actionCreators"
import individualServices from "../../../services/individualServices"
import companyServices from "../../../services/copmanyServices"
import { paths } from "../../../routes/paths"
import freelancerServices from "../../../services/freelancerServices"

const useSearch = () => {
  const history = useHistory()
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, {
    searchParamGroup: {
      query: "",
      ...queryString.parse(location.search, { arrayFormat: "comma" }),
    },
    requestResultGroup: {
      query: "",
      account_type: "individual",
      itemList: [],
      pageCount: 0,
      isFetching: true,
    },
  })
  useEffect(() => {
    const locationSearchGroup = queryString.parse(location.search, {
      arrayFormat: "comma",
    })
    dispatch(setSearchParams(locationSearchGroup))
    switch (locationSearchGroup.account_type) {
      case "individual": {
        individualServices
          .getIndividuals(locationSearchGroup)
          .then(({ data }) => {
            dispatch(
              setRequestResult({
                itemList: data.individuals || [],
                dataTotalCount: data.total_count || 0,
                locationSearchGroup,
              }),
            )
            window.scrollTo(0, 0)
          })
        break
      }
      case "company": {
        companyServices.getCompanies(locationSearchGroup).then(({ data }) => {
          dispatch(
            setRequestResult({
              itemList: data.companies || [],
              dataTotalCount: data.total_count || 0,
              locationSearchGroup,
            }),
          )
          window.scrollTo(0, 0)
        })
        break
      }
      case "freelancers": {
        freelancerServices
          .getFreelancers(locationSearchGroup)
          .then(({ data }) => {
            dispatch(
              setRequestResult({
                itemList: data.freelancers || [],
                dataTotalCount: data.total_count || 0,
                locationSearchGroup,
              }),
            )
            window.scrollTo(0, 0)
          })
        break
      }
    }
  }, [location.search])
  useEffect(() => {
    history.replace(paths.memberSearch(state.searchParamGroup))
  }, [state.searchParamGroup])
  return [state, dispatch]
}
export default useSearch
