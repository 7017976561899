export const options = [
  {
    id: "",
    value: "All",
    role: "",
  },
  {
    id: "",
    value: "All",
    role: "college_students",
  },
  {
    id: "freshman",
    value: "Freshman",
    role: "college_students",
  },
  {
    id: "sophomores",
    value: "Sophomore",
    role: "college_students",
  },
  {
    id: "juniors_seniors",
    value: "Juniors Senior",
    role: "college_students",
  },
  {
    id: "graduate_students",
    value: "Graduate Student",
    role: "college_students",
  },
  {
    id: "",
    value: "All",
    role: "professionals",
  },
  {
    id: "freelancers",
    value: "Freelancer",
    role: "professionals",
  },
  {
    id: "middle_managers",
    value: "Middle Manager",
    role: "professionals",
  },
  {
    id: "recent_graduates",
    value: "Recent Graduate",
    role: "professionals",
  },
  {
    id: "rising_executives",
    value: "Rising Executive",
    role: "professionals",
  },
  {
    id: "senior_executives",
    value: "Senior Executive",
    role: "professionals",
  },
  {
    id: "",
    value: "All",
    role: "business_owners",
  },
  {
    id: "founders",
    value: "Founder",
    role: "business_owners",
  },
  {
    id: "mature",
    value: "Mature",
    role: "business_owners",
  },
  {
    id: "startups",
    value: "Startup",
    role: "business_owners",
  },
  {
    id: "",
    value: "All",
    role: "board_members",
  },
  {
    id: "board_of_directors",
    value: "Board of Directors",
    role: "board_members",
  },
  {
    id: "diverse_company",
    value: "Diverse Company",
    role: "board_members",
  },
  {
    id: "board_associates",
    value: "Board Advisors",
    role: "board_members",
  },
]
