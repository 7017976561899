import React, { useState, useEffect } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Input from "../../../../Input/Input"
import { setLocation, setLocationInput } from "../../useReducer/actionCreators"
import suggestionServices from "../../../../../services/suggestionServices"

const LocationAutocomplete = ({ locationInput, externalDispatch }) => {
  const [options, setOptions] = useState([])
  useEffect(() => {
    suggestionServices
      .getIndividualLocations(locationInput || "")
      .then(({ data }) => {
        setOptions([
          ...new Set(
            data
              .filter((option) => Boolean(option?.trim()))
              .slice(0, 20)
              .sort((a, b) => {
                const str1 = a?.toLowerCase()
                const str2 = b?.toLowerCase()
                if (str1 > str2) {
                  return 1
                } else if (str1 < str2) {
                  return -1
                } else {
                  return 0
                }
              })
              .map((option) => option.trim()),
          ),
        ])
      })
    // eslint-disable-next-line
  }, [locationInput])
  return (
    <Autocomplete
      inputValue={locationInput}
      options={options}
      renderInput={(params) => {
        return (
          <div ref={params.InputProps.ref}>
            <Input
              type="text"
              label="Location Address"
              placeholder="Enter location"
              {...params.inputProps}
            />
          </div>
        )
      }}
      onInputChange={(e, value) => {
        externalDispatch(setLocationInput(value.substring(0, 64)))
      }}
      onChange={(e, location, reason) => {
        if (reason === "select-option") {
          externalDispatch(setLocation(location))
        }
      }}
    />
  )
}
export default LocationAutocomplete
