import React from "react"
import TrueTag from "../../../../components/elements/TrueTag"
import {
  getRoleTypeName,
  getActivityTypeName,
  getGenderTypeName,
  getRaceTypeName,
  getCompanyTypeName,
  getCompanyRoleTypeName,
  getLevelTypeName,
  getCompanyActivityTypeName,
  getCompanyIndustryTypeName,
  getCompanyDepartmentTypeName,
  getCompanyDiverseTypeName,
  getCompanyClassificationList,
  getCompanyMarketName, getCompanyCertifiedTypeName,
} from "./helpers"
import {
  removeJobTitle,
  removeLocation,
  removeSkill,
  removeRoleType,
  removeActivityType,
  removeGenderType,
  removeRaceType,
  removeCompanyType,
  removeLevelType,
  removeCompanyRoleType,
  removeCompanyActivityType,
  removeCompanyIndustryType,
  removeCompanyDepartmentType,
  removeCompanyDiverseType,
  removeCompanyCertified,
  removeCompanyClassificationType,
  removeCompanyMarket,
} from "../../useSearch/actionCreators"
import { businessClassificationOptions } from "../../../../components/CompanyUserInfo/options/businessClassificationOptions"
import styles from "./index.module.scss"

const TagWrapper = ({ classes, requestResultGroup, dispatch }) => {
  const {
    account_type,
    job_title,
    location,
    skill,
    describe_me,
    activity,
    gender,
    race,
    solopreneur,
    level_type,
    describe_company,
    industry,
    department,
    diverse,
    certified,
    classifications,
    markets,
  } = requestResultGroup
  const roleType = getRoleTypeName(describe_me)
  const activityType =
    account_type === "individual" && getActivityTypeName(activity)
  const genderType = getGenderTypeName(gender)
  const raceType = getRaceTypeName(race)
  const companyType = getCompanyTypeName(solopreneur)
  const levelType = getLevelTypeName(level_type)
  const companyRoleType = getCompanyRoleTypeName(describe_company)
  const companyActivityType =
    account_type === "company" && getCompanyActivityTypeName(activity)
  const companyIndustryType = getCompanyIndustryTypeName(industry)
  const companyDepartmentType = getCompanyDepartmentTypeName(department)
  const companyDiverseType = getCompanyDiverseTypeName(diverse)
  const companyCertifiedType = getCompanyCertifiedTypeName(certified)
  const classificationList = getCompanyClassificationList(classifications)
  const marketName = getCompanyMarketName(markets)
  return (
    <div className={classes}>
      {Boolean(job_title) && (
        <TrueTag
          classes={styles.tag}
          title={job_title}
          onClick={() => dispatch(removeJobTitle())}
        />
      )}
      {Boolean(location) && (
        <TrueTag
          classes={styles.tag}
          title={location}
          onClick={() => dispatch(removeLocation())}
        />
      )}
      {Boolean(skill) && (
        <TrueTag
          classes={styles.tag}
          title={skill}
          onClick={() => dispatch(removeSkill())}
        />
      )}
      {Boolean(roleType) && (
        <TrueTag
          classes={styles.tag}
          title={roleType}
          onClick={() => dispatch(removeRoleType())}
        />
      )}
      {Boolean(activityType) && (
        <TrueTag
          classes={styles.tag}
          title={activityType}
          onClick={() => dispatch(removeActivityType())}
        />
      )}
      {Boolean(genderType) && (
        <TrueTag
          classes={styles.tag}
          title={genderType}
          onClick={() => dispatch(removeGenderType())}
        />
      )}
      {Boolean(raceType) && (
        <TrueTag
          classes={styles.tag}
          title={raceType}
          onClick={() => dispatch(removeRaceType())}
        />
      )}
      {Boolean(companyType) && (
          <TrueTag
              classes={styles.tag}
              title={companyType}
              onClick={() => dispatch(removeCompanyType())}
          />
      )}
      {Boolean(levelType) && (
          <TrueTag
              classes={styles.tag}
              title={levelType}
              onClick={() => dispatch(removeLevelType())}
          />
      )}
      {Boolean(companyRoleType) && (
        <TrueTag
          classes={styles.tag}
          title={companyRoleType}
          onClick={() => dispatch(removeCompanyRoleType())}
        />
      )}
      {Boolean(companyActivityType) && (
        <TrueTag
          classes={styles.tag}
          title={companyActivityType}
          onClick={() => dispatch(removeCompanyActivityType())}
        />
      )}
      {Boolean(companyIndustryType) && (
        <TrueTag
          classes={styles.tag}
          title={companyIndustryType}
          onClick={() => dispatch(removeCompanyIndustryType())}
        />
      )}
      {Boolean(companyDepartmentType) && (
        <TrueTag
          classes={styles.tag}
          title={companyDepartmentType}
          onClick={() => dispatch(removeCompanyDepartmentType())}
        />
      )}
      {Boolean(companyDiverseType) && (
        <TrueTag
          classes={styles.tag}
          title={`Diverse: ${companyDiverseType}`}
          onClick={() => dispatch(removeCompanyDiverseType())}
        />
      )}
      {Boolean(companyCertifiedType) && (
        <TrueTag
          classes={styles.tag}
          title={`Certified: ${companyCertifiedType}`}
          onClick={() => dispatch(removeCompanyCertified())}
        />
      )}
      {classificationList.map((classificationType) => {
        const title = businessClassificationOptions.find(
          (option) => option.databaseName === classificationType,
        )?.value
        return (
          <TrueTag
            classes={styles.tag}
            title={title}
            onClick={() =>
              dispatch(removeCompanyClassificationType(classificationType))
            }
          />
        )
      })}
      {Boolean(marketName) && (
        <TrueTag
          classes={styles.tag}
          title={marketName}
          onClick={() => dispatch(removeCompanyMarket())}
        />
      )}
    </div>
  )
}
export default TagWrapper
