import React, { useState } from "react"
import { withRouter, Redirect } from "react-router-dom"
import SignUpStep1Container from "../../containers/SignUpContainer/SignUpStep1Container"
import SignUpCompnayStep2Container from "../../containers/SignUpContainer/SignUpCompnayStep2Container"
import SignUpIndividualStep2Container from "../../containers/SignUpContainer/SignUpIndividualStep2Container"
import { paths } from "../../routes/paths"
import services from "../../services"
import SignUpCompanyStep3Container from "../../containers/SignUpContainer/SignUpCompanyStep3Container"

const SignUpPage = ({ history, match }) => {
  const [user, setUser] = useState({})
  const params = match.params

  const onHandleNext = () => {
    if (user.role === "individual") {
      history.push(paths.signUp("/individual", "2"))
    } else if (params.step === 1) {
      history.push(paths.signUp("/company", "2"))
    } else if (user.role === "company" && user.type) {
      history.push(paths.signUp("/company", "3"))
    } else if (user.role === "company") {
      history.push(paths.signUp("/company", "2"))
    }
  }
  const onHandleNext2 = () => {
    document.querySelector("button[type='submit']").click()
  }

  const onHandleBack = () => {
    history.push(paths.signUp("", 1))
  }

  const onHandleBackStep3 = () => {
    history.push(paths.signUp("/company", "2"))
  }

  const onHandleRole = (role) => {
    setUser({ ...user, role })
  }

  const RedirectToStart = () => <Redirect to={paths.signUp("", "1")} />

  const handleAccountTypeChange = (type) => {
    setUser({ ...user, type })
  }

  const handleSaveIndividualUser = async ({ fullName, password, email }) => {
    const params = {
      account_type: "individual",
      full_name: fullName,
      password,
      email,
      role: user.role,
    }

    try {
      await services.userServices
        .signUp(params)
        .then(() => history.push(paths.emailVerifyResend(params.email)))
    } catch (error) {
      throw error
    }
  }
  const handleSaveCompanyUser = async (values) => {
    const params = {
      account_type: "company_admin",
      full_name: values.fullName.trim(),
      email: values.email,
      password: values.password,
      company_type: user.type,
      company_name: values.companyName.trim(),
      team_size: values.teamSize.dataName,
    }

    try {
      await services.userServices
        .signUp(params)
        .then(() => history.push(paths.emailVerifyResend(params.email)))
    } catch (error) {
      throw error
    }
  }

  switch (params.step) {
    case "1": {
      return (
        <SignUpStep1Container
          user={user}
          onHandleNext={onHandleNext}
          onHandleRole={onHandleRole}
        />
      )
    }
    case "2": {
      if (!user.role) {
        RedirectToStart()
      }
      if (params.type === "company") {
        return (
          <SignUpCompnayStep2Container
            user={user}
            onBack={onHandleBack}
            onAccountTypeChange={handleAccountTypeChange}
            onHandleNext={onHandleNext}
          />
        )
      } else {
        return (
          <SignUpIndividualStep2Container
            handleSaveUser={handleSaveIndividualUser}
            onBack={onHandleBack}
            onHandleNext={onHandleNext2}
          />
        )
      }
    }
    case "3": {
      if (!user.type) {
        RedirectToStart()
      }
      return (
        <SignUpCompanyStep3Container
          handleSaveUser={handleSaveCompanyUser}
          onBack={onHandleBackStep3}
          onHandleNext={onHandleNext2}
          user={user}
        />
      )
    }
    default:
      RedirectToStart()
  }

  // if (params.step === "1") {
  //   return (
  //     <SignUpStep1Container
  //       user={user}
  //       onHandleNext={onHandleNext}
  //       onHandleRole={onHandleRole}
  //     />
  //   )
  // } else if (params.step === "2" && user.role) {
  //   if (params.type === "company") {
  //     return (
  //       <SignUpCompnayStep2Container
  //         user={user}
  //         onBack={onHandleBack}
  //         onAccountTypeChange={handleAccountTypeChange}
  //         onHandleNext={onHandleNext}
  //       />
  //     )
  //   } else {
  //     return (
  //       <SignUpIndividualStep2Container
  //         handleSaveUser={handleSaveIndividualUser}
  //         onBack={onHandleBack}
  //         onHandleNext={onHandleNext2}
  //       />
  //     )
  //   }
  // } else if (params.step === "3" && user.type) {
  //   return (
  //     <SignUpCompanyStep3Container
  //       handleSaveUser={handleSaveCompanyUser}
  //       onBack={onHandleBackStep3}
  //       onHandleNext={onHandleNext2}
  //       user={user}
  //     />
  //   )
  // } else {
  //   return <Redirect to={paths.signUp("", "1")} />
  // }
}

export default withRouter(SignUpPage)
