import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { withFormik } from "formik"
import { object, string } from "yup"
import styles from "./SignInForm.module.scss"
import Input from "../../components/Input/Input"
import PasswordInput from "../../components/PasswordInput/PasswordInput"
import Button from "../../components/Button/Button"
import { ReactComponent as Mail } from "../../images/icons/mail.svg"

const SignInForm = ({
  values,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  isSubmitting,
}) => {
  return (
    <form className={styles.signInForm} onSubmit={handleSubmit}>
      <div className={styles.inlineWrap}>
        <h4 className={styles.title}>Sign In</h4>

        <div className={styles.fieldWrap}>
          <Input
            type="email"
            name="email"
            label="Email"
            value={values.email}
            placeholder="Type your email"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            errorMessage={errors.email && touched.email && errors.email}
            afterIcon={<Mail />}
          />
        </div>
        <div className={styles.fieldWrap}>
          <PasswordInput
            placeholder="Type your password"
            label="Password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.password && touched.password}
            errorMessage={
              errors.password && touched.password && errors.password
            }
            value={values.password}
          />
        </div>
        <Button
          className={styles.signInBtn}
          width="100%"
          disabled={isSubmitting || !values.password || !values.email}
          type="submit"
        >
          Sign In
        </Button>
        <Link
          to="/reset-password"
          className={classNames("goldLink", styles.forgotPassLink)}
        >
          Forgot Password?
        </Link>
        {/*<span className={classNames("note14r", styles.signUp)}>*/}
        {/*  Don’t have an account?*/}
        {/*  <Link*/}
        {/*    to="/sign-up/1"*/}
        {/*    className={classNames("goldLink", styles.signUpLink)}*/}
        {/*  >*/}
        {/*    Create an account*/}
        {/*  </Link>*/}
        {/*</span>*/}
      </div>
    </form>
  )
}

const FormikApp = withFormik({
  mapPropsToValues() {
    return {
      email: "",
      password: "",
    }
  },
  validationSchema: () =>
    object().shape({
      email: string()
        .email("Please enter your email address in format yourname@example.com")
        .max(
          200,
          "Please enter a valid email address that consists of no more than 200 characters",
        )
        .required("Please enter email address"),
      password: string().required("Please enter a password"),
    }),
  handleSubmit(
    values,
    { setSubmitting, setFieldError, props: { handleLogin } },
  ) {
    handleLogin(values)
      .then(() => {
        setSubmitting(false)
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setFieldError(
            "email",
            "Couldn`t find an account with such credentials",
          )
        }
        if (e.response && e.response.status === 401) {
          setFieldError("password", "Wrong password. Try again, please.")
        }
        setSubmitting(false)
      })
  },
})(SignInForm)

export default FormikApp
