import React, { useEffect, useState } from "react"
import Input from "../../../../Input/Input"
import styles from "./TopFiveItem.module.scss"
import PropTypes from "prop-types"
import validation from "./validation"

const TopFiveItem = ({
  placeholder,
  onChange,
  propValue,
  showData,
  getInputValidStatus,
  index,
  setHasChanges,
  isNeedToValidate,
  autoFocus,
}) => {
  const [value, setValue] = useState()
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    setValue(propValue)
  }, [propValue, showData])

  const handleValidate = () => {
    const { errorMessage } = validation(value, isNeedToValidate)
    getInputValidStatus(index, !errorMessage.length)
    setErrorMessage(errorMessage)
  }

  useEffect(() => {
    setErrorMessage("")
    if (value !== "undefined" && isNeedToValidate > 0) {
      handleValidate()
    }
    if (isNeedToValidate === 0) {
      setErrorMessage("")
    }
  }, [isNeedToValidate])

  const HandleChangeInput = (value) => {
    const { errorMessage } = validation(value)
    setHasChanges(true)
    setValue(value)
    onChange(index, value)
    getInputValidStatus(index, !errorMessage.length)
    setErrorMessage(errorMessage)
  }
  if (showData && propValue.trim().length === 0) return null
  return (
    <>
      {showData ? (
        <>{propValue && <div className={styles.showField}>{value}</div>}</>
      ) : (
        <>
          <Input
            fieldStyle="dark"
            className={styles.field}
            type="text"
            errorMessage={errorMessage}
            error={errorMessage}
            onChange={(e) => HandleChangeInput(e.target.value)}
            value={value}
            placeholder={placeholder}
            autoFocus={autoFocus}
          />
        </>
      )}
    </>
  )
}

TopFiveItem.propTypes = {
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  propValue: PropTypes.string,
  showData: PropTypes.bool,
  index: PropTypes.number,
  setHasChanges: PropTypes.func,
}

TopFiveItem.defaultProps = {
  placeholder: " ",
  propValue: "",
}

export default TopFiveItem
