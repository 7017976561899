import React from "react"
import { withFormik } from "formik"
import { string, object, ref } from "yup"
import Grid from "@material-ui/core/Grid"

import styles from "./SignUpForms.module.scss"
import Input from "../../components/Input/Input"
import PasswordInput from "../../components/PasswordInput/PasswordInput"
import { isHaveBadWords } from "../../helpers/badWords"

const IndividualStep2Form = ({
  values,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  isSubmitting,
}) => {
  return (
    <form className={styles.form} onSubmit={handleSubmit} id="formikForm">
      <h4 className={styles.title}>Individual Registration</h4>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Input
            name="fullName"
            className={styles.field}
            placeholder="Type your full name"
            label="Full name"
            value={values.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.fullName && touched.fullName}
            errorMessage={
              errors.fullName && touched.fullName && errors.fullName
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            name="email"
            type="email"
            className={styles.field}
            placeholder="Type your email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            errorMessage={errors.email && touched.email && errors.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PasswordInput
            name="password"
            className={styles.passField}
            label="Password"
            placeholder="Type your password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password && touched.password}
            errorMessage={
              errors.password && touched.password && errors.password
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PasswordInput
            name="confirmPassword"
            className={styles.passField}
            label="Confirm password"
            placeholder="Confirm your password"
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.confirmPassword && touched.confirmPassword}
            errorMessage={
              errors.confirmPassword &&
              touched.confirmPassword &&
              errors.confirmPassword
            }
          />
        </Grid>
      </Grid>
      <button disabled={isSubmitting} style={{ display: "none" }} type="submit">
        Sumbit form
      </button>
    </form>
  )
}

const FormikApp = withFormik({
  mapPropsToValues() {
    return {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
    }
  },
  validationSchema: () =>
    object().shape({
      fullName: string()
        .trim()
        .matches(
          /^[a-zA-Z\s_\-_'_"]*$/,
          "Enter a correct Full Name that doesn't contain any special characters or numbers",
        )
        .matches(/[^\\s*]+/, "Please enter full name")
        .min(
          2,
          "Please enter a correct full name that consists of a minimum 1 character",
        )
        .max(
          32,
          "Please enter a correct full name that consists of no more than 32 characters",
        )
        .test({
          name: "description_obj",
          test: (val) => {
            if (!val) return true
            if (val) {
              return !isHaveBadWords(val)
            }
          },
          message: `Please don't use bad language`,
        })
        .required("Please enter full name"),
      email: string()
        .email("Please enter your email address in format yourname@example.com")
        .max(
          200,
          "Please enter a valid email address that consists of no more than 200 characters",
        )
        .required("Please enter email address"),
      password: string()
        .min(
          8,
          "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .matches(
          /(?=.*[a-z])/,
          "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .matches(
          /(?=.*[A-Z])/,
          "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .matches(
          /(?=.*\d)/,
          "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .matches(
          /(?=.*\W|_)/,
          "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .max(
          32,
          "Please enter a valid password that consists of no more than 32 characters",
        )
        .required("Please enter a password"),
      confirmPassword: string()
        .oneOf(
          [ref("password"), null],
          "Entered passwords didn`t match. Try again.",
        )
        .required("Please confirm your password"),
    }),
  handleSubmit(
    values,
    { setSubmitting, setFieldError, props: { handleSaveUser } },
  ) {
    setSubmitting(true)
    handleSaveUser(values).catch((error) => {
      if (error.response.status === 422) {
        setFieldError(
          "email",
          "That email address is already taken. Try another.",
        )
        setSubmitting(false)
      }
    })
  },
})(IndividualStep2Form)

export default FormikApp
