import meetupImg from "../images/resources/meetup-bg.png"
import adviceImg from "../images/resources/advice-bg.png"
import etiquetteImg from "../images/resources/etiquette-bg.png"
import fundingImg from "../images/resources/funding-bg.png"
import unspokenRulesImg from "../images/resources/unspoken_rules-bg.png"
import {
  COMPANY_EMPLOYEE,
  COMPANY_PROVIDER,
  COMPANY_SEEKER,
  INDIVIDUAL,
} from "./userRoles"

export const months = [
  { id: "01", value: "January" },
  { id: "02", value: "February" },
  { id: "03", value: "March" },
  { id: "04", value: "April" },
  { id: "05", value: "May" },
  { id: "06", value: "June" },
  { id: "07", value: "July" },
  { id: "08", value: "August" },
  { id: "09", value: "September" },
  { id: "10", value: "October" },
  { id: "11", value: "November" },
  { id: "12", value: "December" },
]

export const teamSize = [
  { id: "00", value: "0 - 1 people", dataName: "zero_to_one"},
  { id: "01", value: "1 - 5 people", dataName: "one_to_five" },
  { id: "02", value: "5 - 20 people", dataName: "five_to_twenty" },
  { id: "03", value: "20 - 50 people", dataName: "twenty_to_fifty" },
  { id: "05", value: "50 - 100 people", dataName: "fifty_to_hundred" },
  { id: "06", value: "100 - 500 people", dataName: "hundred_to_five_hundred" },
  {
    id: "07",
    value: "500 - 1000 people",
    dataName: "five_hundred_to_thousand",
  },
  { id: "08", value: "1000+ people", dataName: "thousand_plus" },
]

export const years = (min, max) => {
  if (typeof min === "number" && typeof max === "number") {
    const yearsArray = []
    let curYear = min
    let curIndex = 1

    while (curYear <= max) {
      yearsArray.push({ id: String(curIndex), value: String(curYear) })
      curYear++
      curIndex++
    }
    return yearsArray
  }
}

export const getCompanyUserTypeLabel = (type) => {
  switch (type) {
    case "company_seeker": {
      return "Seeker"
    }
    case "company_provider": {
      return "Provider"
    }
  }
}

export const resourceTypes = [
  {
    resourceType: "meetup",
    badgeText: "Meetup",
    badgeColor: "#6B4B7E",
    image: meetupImg,
  },
  {
    resourceType: "advice",
    badgeText: "Advice",
    badgeColor: "#CB5F38",
    image: adviceImg,
  },
  {
    resourceType: "etiquette",
    badgeText: "Etiquette",
    badgeColor: "#6A808A",
    image: etiquetteImg,
  },
  {
    resourceType: "funding",
    badgeText: "Funding",
    badgeColor: "#0f4c82",
    image: fundingImg,
  },
  {
    resourceType: "unspoken_rules",
    badgeText: "Unspoken rules",
    badgeColor: "#983A46",
    image: unspokenRulesImg,
  },
]

export const userTypes = [
  {
    userType: INDIVIDUAL,
    badgeText: "Individual",
    badgeColor: "#6b4b7e",
  },
  {
    userType: COMPANY_PROVIDER,
    badgeText: "Company provider",
    badgeColor: "#311B92",
  },
  {
    userType: COMPANY_SEEKER,
    badgeText: "Company seeker",
    badgeColor: "#983A46",
  },
  {
    userType: COMPANY_EMPLOYEE,
    badgeText: "Company provider",
    badgeColor: "#6b4b7e",
  },
]

export const questionTypes = [
  {
    questionType: "peer_circles",
    badgeText: "Peer Circles",
    badgeColor: "#6b4b7e",
    tooltipText: "Connect or invite B2T members to join your peer circle ",
  },
  {
    questionType: "group_chats",
    badgeText: "Group Chats",
    badgeColor: "#7986cb",
    tooltipText:
      "Connect with other members in the B2T community by sharing ideas, asking and answering questions. ",
  },
  {
    questionType: "referrals",
    badgeText: "Referrals",
    badgeColor: "#983a46",
    tooltipText:
      "Refer a great company for business, projects or personal use. ",
  },
  {
    questionType: "the_vent",
    badgeText: "Let’s Connect",
    badgeColor: "#321b92",
    tooltipText:
      "Your B2T community is here to help you navigate professional pain points and challenges.",
  },
  {
    questionType: "network",
    badgeText: "Network",
    badgeColor: "#104c82",
    tooltipText:
      "Network with the best talent, companies, opportunities and projects.",
  },
]
