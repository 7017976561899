import { isHaveBadWords } from "../../../../../helpers/badWords"

export default function validation(value) {
  if (value && !value.match(/^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/)) {
    //for only cyrillic
  }
  if (value && value.length > 32 && value.trim().length !== 0)
    return {
      validation: false,
      errorMessage:
        'Please enter a correct "My top 5 " item that consists of no more than 32 characters',
    }
  if (isHaveBadWords(value)) {
    return {
      validation: false,
      errorMessage: "Please don't use bad language",
    }
  }
  return {
    validation: true,
    errorMessage: "",
  }
}
