import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

import detailsImg from "../../images/resources/resource-detail-img.jpg"
import { ReactComponent as ClockIcon } from "../../images/icons/clock.svg"
import { ReactComponent as DollarIcon } from "../../images/icons/dollar-filled.svg"
import { ReactComponent as UserIcon } from "../../images/icons/user.svg"
import { ReactComponent as UsersIcon } from "../../images/icons/users.svg"
import { ReactComponent as ChatIcon } from "../../images/icons/chat.svg"
import { ReactComponent as LocationIcon } from "../../images/icons/location.svg"
import styles from "./index.module.scss"
import { resourceTypes } from "../../helpers/helpData"
import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter"
import VideoPlayer from "../VideoPlayer/VideoPlayer"
import CopyToClipboard from "../CopyToClipboard"

const ResourcePageDetails = ({ data }) => {
  return (
    <div className={styles.resourceDetailsWrap}>
      <div className={styles.resourceDetailsContent}>
        <ul>
          <li>
            <div className={styles.resourceDetailsItem}>
              <div className={styles.resourceDetailsIcon}>
                <ClockIcon />
              </div>

              <div className={styles.resourceDetailsText}>
                {moment(data.date.start).format("MMM DD, YYYY")}
                {data.date.start !== data.date.end &&
                  moment(data.date.end).format(" - MMM DD, YYYY")}
                <br />
                {data.time}
              </div>
            </div>
          </li>

          <li>
            <div className={styles.resourceDetailsItem}>
              <div className={styles.resourceDetailsIcon}>
                <DollarIcon />
              </div>

              <div className={styles.resourceDetailsText}>
                {data.cost ? `${data.cost}` : "Free"}
              </div>
            </div>
          </li>

          <li>
            <div className={styles.resourceDetailsItem}>
              <div className={styles.resourceDetailsIcon}>
                <UserIcon />
              </div>

              <div className={styles.resourceDetailsText}>
                <CopyToClipboard text={data.contact} />
              </div>
            </div>
          </li>

          <li>
            <div className={styles.resourceDetailsItem}>
              <div className={styles.resourceDetailsIcon}>
                <UsersIcon />
              </div>

              <div className={styles.resourceDetailsText}>
                {capitalizeFirstLetter(data.event_type)}{" "}
                {getEventType(data.resource_type)}
              </div>
            </div>
          </li>

          <li>
            <div className={styles.resourceDetailsItem}>
              <div className={styles.resourceDetailsIcon}>
                <ChatIcon />
              </div>

              <div className={styles.resourceDetailsText}>
                {capitalizeFirstLetter(data.sub_type)}
              </div>
            </div>
          </li>

          <li>
            <div className={styles.resourceDetailsItem}>
              <div className={styles.resourceDetailsIcon}>
                <LocationIcon />
              </div>

              <div className={styles.resourceDetailsText}>{data.location}</div>
            </div>
          </li>
        </ul>
      </div>

      <div className={styles.resourceDetailsImg}>
        {data.video ? (
          <VideoPlayer source={data.video.path} />
        ) : (
          <img
            src={data.photo?.path || detailsImg}
            alt="Resource Page Details"
          />
        )}
      </div>
    </div>
  )
}

ResourcePageDetails.propTypes = {
  data: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
}

export default ResourcePageDetails

const getEventType = (type) => {
  const obj = resourceTypes.find((item) => item.resourceType === type)
  return obj?.badgeText
}
