import React from "react"
import { useHistory } from "react-router-dom"
import { paths } from "../../../../routes/paths"
import CompanyCard from "../../../../components/cards/CompanyCard"
import IndividualCard from "../../../../components/cards/IndividualCard"
import Pagination from "../../../../components/elements/Pagination"
import { setSearchPage } from "../../useSearch/actionCreators"
import { checkIsFilterUsed } from "./helpers"
import services from "../../../../services"
import styles from "./index.module.scss"
import FreelancerCard from "../../../../components/cards/FreelancerCard"

type Company = {
  uuid: string
  photo: string
  name: string
  describe_company: string
  company_type: string,
  activity: string
  chat: string
  description: string
  user_uuid: string
  industry: string
  favorite: Boolean
}
type Individual = {
  uuid: string
  photo: string
  full_name: string
  describe_me: string
  activity: string
  skills: string
  chat: string
  user_uuid: string
  race: string
  gender: string
  favorite: Boolean
}
type Freelancer = {
  description: string
  external_id: string
  photo: string
  name: string
  title: string
  uuid: string
  favorite: boolean
  chat: string
}
type MainWrapperProps = {
  state: {
    searchParamGroup: {
      page: string
    }
    requestResultGroup: {
      itemList: (Company | Individual | Freelancer)[]
      memberCount: number
      pageCount: number
      isFetching: boolean
      account_type: string
      job_title: string
      location: string
      describe_me: string
      activity: string
      gender: string
      race: string
      page: string
      describe_company: string
      industry: string
      department: string
    }
  }
  dispatch: Function
  userUUID: string
  companyUUID: string | undefined
  isHaveChatButton: boolean
}
const MainWrapper = ({
  state,
  dispatch,
  userUUID,
  companyUUID,
  isHaveChatButton,
}: MainWrapperProps) => {
  const history = useHistory()
  const { itemList, memberCount, pageCount, isFetching, account_type } =
    state.requestResultGroup
  const openChat = (chatUuid: string, userUuid: string, userType: string) => {
    if (!chatUuid) {
      services.chatServices
        .createChatRoom({ type: userType, uuid: userUuid })
        .then((result) => history.push(paths.chats(result.data.uuid)))
        .catch((error) => console.log(error))
    } else {
      history.push(paths.chats(chatUuid))
    }
  }

  const openFreelancerProfile = async (freelancer: Freelancer) =>
    history.push(`/freelancer/${freelancer.external_id}`)

  if (isFetching) return null
  const isFilterUsed = checkIsFilterUsed(state.requestResultGroup)
  return (
    <>
      {!!memberCount && (
        <>
          <div className={styles.itemList}>
            {account_type === "individual" &&
              (itemList as Individual[]).map((individual: Individual) => (
                <IndividualCard
                  key={individual.uuid}
                  classes={styles.card}
                  individualAvatar={individual.photo}
                  individualName={individual.full_name}
                  userUuid={individual.uuid}
                  chatUuid={individual.chat}
                  individualRole={individual.describe_me}
                  individualActivity={individual.activity}
                  description={individual.skills}
                  isOwned={userUUID === individual.uuid}
                  race={individual.race}
                  gender={individual.gender}
                  onClick={() =>
                    history.push(
                      paths.selectedIndividualProfile(individual.uuid),
                    )
                  }
                  onChatOpen={openChat}
                  isFavorite={individual.favorite}
                />
              ))}
            {account_type === "company" &&
              (itemList as Company[]).map((company: Company) => (
                <CompanyCard
                  isHaveChatButton={isHaveChatButton}
                  key={company.uuid}
                  companyUuid={company.uuid}
                  chatUuid={company.chat}
                  classes={styles.card}
                  companyAvatar={company.photo}
                  companyName={company.name}
                  companyType={company.company_type}
                  companyRole={company.describe_company}
                  companyActivity={company.activity}
                  description={company.description}
                  isOwned={companyUUID === company.uuid}
                  industry={company.industry}
                  onClick={() =>
                    history.push(
                      paths.selectedCopmanyProfile(company.uuid, "search"),
                    )
                  }
                  onChatOpen={openChat}
                  isFavorite={company.favorite}
                />
              ))}
            {account_type === "freelancers" &&
              (itemList as Freelancer[]).map((freelancer: Freelancer) => (
                <FreelancerCard
                  freelancerAvatar={freelancer.photo}
                  freelancerName={freelancer.name}
                  freelancerUuid={freelancer.uuid}
                  chatUuid={freelancer.chat}
                  description={freelancer.description}
                  isOwned={false}
                  onClick={() => openFreelancerProfile(freelancer)}
                  onChatOpen={openChat}
                  isHaveChatButton={true}
                  isFavorite={freelancer.favorite}
                  skills={freelancer.title}
                  classes={styles.card}
                  externalId={freelancer.external_id}
                />
              ))}
          </div>
          {pageCount > 1 && (
            <div className={styles.pagination}>
              <Pagination
                currentPageNumber={parseInt(state.requestResultGroup.page)}
                totalPageCount={pageCount}
                onSelect={(pageNumber) => dispatch(setSearchPage(pageNumber))}
              />
            </div>
          )}
        </>
      )}
      {!memberCount && !isFilterUsed && (
        <p className={styles.notFoundBlock}>
          Sorry, we found 0 matches.
          <br />
          Please use other keywords or filters.
        </p>
      )}
      {!memberCount && isFilterUsed && (
        <p className={styles.notFoundBlock}>
          There are no results that match your search.
          <br />
          Please try adjusting your filters.
        </p>
      )}
    </>
  )
}
export default MainWrapper
