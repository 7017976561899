export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS"
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE"
export const SET_FORM_FILTERS = "SET_FORM_FILTERS"
export const RESET_FORM_FILTERS = "RESET_FORM_FILTERS"
export const REMOVE_JOB_TITLE = "REMOVE_JOB_TITLE"
export const REMOVE_LOCATION = "REMOVE_LOCATION"
export const REMOVE_SKILL = "REMOVE_SKILL"
export const REMOVE_ROLE_TYPE = "REMOVE_ROLE_TYPE"
export const REMOVE_ACTIVITY_TYPE = "REMOVE_ACTIVITY_TYPE"
export const REMOVE_GENDER_TYPE = "REMOVE_GENDER_TYPE"
export const REMOVE_RACE_TYPE = "REMOVE_RACE_TYPE"
export const REMOVE_COMPANY_TYPE = "REMOVE_COMPANY_TYPE"
export const REMOVE_LEVEL_TYPE = "REMOVE_LEVEL_TYPE"
export const REMOVE_COMPANY_ROLE_TYPE = "REMOVE_COMPANY_ROLE_TYPE"
export const REMOVE_COMPANY_ACTIVITY_TYPE = "REMOVE_COMPANY_ACTIVITY_TYPE"
export const REMOVE_COMPANY_INDUSTRY_TYPE = "REMOVE_COMPANY_INDUSTRY_TYPE"
export const REMOVE_COMPANY_DEPARTMENT_TYPE = "REMOVE_COMPANY_DEPARTMENT_TYPE"
export const REMOVE_COMPANY_DIVERSE_TYPE = "REMOVE_COMPANY_DIVERSE_TYPE"
export const REMOVE_COMPANY_CERTIFIED = "REMOVE_COMPANY_CERTIFIED"
export const REMOVE_COMPANY_CLASSIFICATION_TYPE =
  "REMOVE_COMPANY_CLASSIFICATION_TYPE"
export const REMOVE_COMPANY_MARKET = "REMOVE_COMPANY_MARKET"
export const SET_REQUEST_RESULT = "SET_REQUEST_RESULT"

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_QUERY: {
      const { query } = payload
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, page: 1, query },
      }
    }
    case SET_SEARCH_PAGE: {
      const { pageNumber } = payload
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, page: pageNumber },
      }
    }
    case SET_FORM_FILTERS: {
      const {
        filters: {
          accountType,
          jobTitle,
          location,
          skill,
          roleType,
          activityType,
          genderType,
          raceType,
          companyType,
          levelType,
          companyRoleType,
          companyActivityType,
          companyIndustryType,
          companyDepartmentType,
          companyDiverseType,
          companyCertified,
          companyClassification,
          companyMarket,
          categoryType,
          freelancersCountry
        },
      } = payload
      console.log("payload", payload);
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          ...(accountType === "individual"
            && {
                account_type: "individual",
                job_title: jobTitle ? jobTitle : "",
                location: location ? location : "",
                skill: skill ? skill : "",
                describe_me: roleType ? roleType : "",
                activity: activityType ? activityType : "",
                gender: genderType ? genderType : "",
                race: raceType ? raceType : "",
                describe_company: "",
                industry: "",
                department: "",
                solopreneur: "",
                level_type: "",
              }),
          ...(accountType === "company"
              && {
                account_type: "company",
                job_title: "",
                location: "",
                skill: "",
                describe_me: "",
                gender: "",
                race: "",
                solopreneur: companyType ? companyType : "",
                level_type: levelType ? levelType : "",
                describe_company: companyRoleType ? companyRoleType : "",
                activity: companyActivityType ? companyActivityType : "",
                industry: companyIndustryType ? companyIndustryType : "",
                department: companyDepartmentType ? companyDepartmentType : "",
                diverse: companyDiverseType ? companyDiverseType : "",
                certified: companyCertified ? companyCertified : "",
                classifications: companyClassification
                    ? companyClassification
                    : "",
                markets: companyMarket ? companyMarket : "",
              }),
          ...(accountType === "freelancers"
              && {
                account_type: "freelancers",
                category: categoryType ? categoryType : "",
                country: freelancersCountry ? freelancersCountry : "",
                job_title: "",
                location: "",
                skill: "",
                describe_me: "",
                gender: "",
                race: "",
                describe_company: "",
                activity: "",
                industry:  "",
                department:  "",
                diverse:  "",
                classifications:"",
                markets: "",
                solopreneur: "",
                level_type: "",
              }),
          page: 1,
        },
      }
    }
    case RESET_FORM_FILTERS: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          job_title: "",
          location: "",
          skill: "",
          describe_me: "",
          activity: "",
          gender: "",
          race: "",
          solopreneur: "",
          level_type: "",
          describe_company: "",
          industry: "",
          department: "",
          diverse: "",
          certified: "",
          classifications: "",
          markets: "",
          category: "",
          page: 1,
        },
      }
    }
    case REMOVE_JOB_TITLE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          job_title: "",
          page: 1,
        },
      }
    }
    case REMOVE_LOCATION: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          location: "",
          page: 1,
        },
      }
    }
    case REMOVE_SKILL: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          skill: "",
          page: 1,
        },
      }
    }
    case REMOVE_ROLE_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          describe_me: "",
          activity: "",
          page: 1,
        },
      }
    }
    case REMOVE_ACTIVITY_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          activity: "",
          page: 1,
        },
      }
    }
    case REMOVE_GENDER_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          gender: "",
          page: 1,
        },
      }
    }
    case REMOVE_RACE_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          race: "",
          page: 1,
        },
      }
    }
    case REMOVE_COMPANY_TYPE: {
        return {
            ...state,
            searchParamGroup: {
                ...state.searchParamGroup,
                solopreneur: "",
                page: 1,
            },
        }
    }
    case REMOVE_LEVEL_TYPE: {
        return {
            ...state,
            searchParamGroup: {
                ...state.searchParamGroup,
                level_type: "",
                page: 1,
            },
        }
    }
    case REMOVE_COMPANY_ROLE_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          describe_company: "",
          page: 1,
        },
      }
    }
    case REMOVE_COMPANY_ACTIVITY_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          activity: "",
          page: 1,
        },
      }
    }
    case REMOVE_COMPANY_INDUSTRY_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          industry: "",
          page: 1,
        },
      }
    }
    case REMOVE_COMPANY_DEPARTMENT_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          department: "",
          page: 1,
        },
      }
    }
    case REMOVE_COMPANY_DIVERSE_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          diverse: "",
          page: 1,
        },
      }
    }
    case REMOVE_COMPANY_CERTIFIED: {
        return {
            ...state,
            searchParamGroup: {
                ...state.searchParamGroup,
                certified: "",
                page: 1,
            },
        }
    }
    case REMOVE_COMPANY_CLASSIFICATION_TYPE: {
      const { classificationType } = payload
      const { classifications } = state.searchParamGroup
      let classificationList = []
      if (classifications && typeof classifications === "string") {
        classificationList = classifications.split(",")
      } else if (classifications?.length) {
        classificationList = classifications
      }
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          classifications: classificationList
            .filter((option) => option !== classificationType)
            .join(","),
          page: 1,
        },
      }
    }
    case REMOVE_COMPANY_MARKET: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          markets: "",
          page: 1,
        },
      }
    }
    case SET_SEARCH_PARAMS: {
      const { locationSearchGroup } = payload
      return {
        ...state,
        searchParamGroup: {
          // ...state.searchParamGroup,
          ...locationSearchGroup,
        },
      }
    }
    case SET_REQUEST_RESULT: {
      const { itemList, dataTotalCount, locationSearchGroup } = payload
      return {
        ...state,
        requestResultGroup: {
          itemList,
          memberCount: dataTotalCount,
          pageCount: Math.ceil(dataTotalCount / 8),
          page: 1,
          query: "",
          ...locationSearchGroup,
        },
      }
    }
    default:
      throw Error("Unknown action type")
  }
}
