export const jobTitleOptions = [
  { id: 1, value: "Chairman", databaseName: "1" },
  { id: 2, value: "Chief Executive Officer/President", databaseName: "2" },
  { id: 3, value: "Vice President", databaseName: "3" },
  { id: 4, value: "Director", databaseName: "4" },
  { id: 5, value: "Professional", databaseName: "5" },
  { id: 6, value: "General Counsel", databaseName: "6" },
  { id: 7, value: "Chief Financial Officer", databaseName: "7" },
  { id: 8, value: "Chief Information Officer/ Chief Technology Officer", databaseName: "8" },
  { id: 9, value: "Chief human Resources Officer", databaseName: "9" },
  { id: 10, value: "Other", databaseName: "10" },
]
