import React, { useState } from "react"
import { Link, Prompt, useHistory } from "react-router-dom"
import { messages } from "../../helpers/constants"
import { useSelector } from "react-redux"
import SearchFormWrapper from "./wrappers/SearchFormWrapper"
import CaptionWrapper from "./wrappers/CaptionWrapper"
import TagWrapper from "./wrappers/TagWrapper"
import MainWrapper from "./wrappers/MainWrapper"
import Button from "../../components/Button/Button"
import ResourceTypeFilter from "../../components/filters/ResourceTypeFilter"
import ResourceSearchFilter from "../../components/filters/ResourceSearchFilter"
import useSearch from "./useSearch"
import {
  setTabFilter,
  setFormFilters,
  resetFormFilters,
} from "./useSearch/actionCreators"
import { COMPANY_PROVIDER } from "../../helpers/userRoles"
import { paths } from "../../routes/paths"
import styles from "./index.module.scss"
import CompanyAddonTypePopup from "../../components/CompanyAddonTypePopup"
import { resourcesTypeCards } from "../../components/CompanyResources/helpers"

const ResourceSearchContainer = () => {
  const history = useHistory()
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [state, dispatch] = useSearch()
  const { searchParamGroup } = state
  const userRole = useSelector((state) => state.user.company)?.type
  const companyUuid = useSelector((state) => state.user.company.uuid)

  return (
    <div className={styles.searchContainer}>
      {isPopupOpened && (
        <CompanyAddonTypePopup
          width={1184}
          data={resourcesTypeCards}
          title={"Add Resource"}
          handleNextClick={({ type }) =>
            history.push(`/resources-edit?type=${type}`)
          }
          closeAction={() => setIsPopupOpened(false)}
        />
      )}
      <SearchFormWrapper state={state} dispatch={dispatch} />
      <CaptionWrapper state={state} />
      <div className={styles.container}>
        <ResourceTypeFilter
          classes={styles.tabFilter}
          searchParamGroup={state.searchParamGroup}
          onSelectType={(resourceType) => dispatch(setTabFilter(resourceType))}
        />
        <div className={styles.body}>
          <main>
            <TagWrapper
              classes={styles.tagBlock}
              searchParamGroup={state.searchParamGroup}
              dispatch={dispatch}
            />
            <MainWrapper state={state} dispatch={dispatch} />
          </main>
          <aside>
            <div className={styles.sideBar}>
              {userRole === COMPANY_PROVIDER && (
                <>
                  <Link
                    to={`/company/${companyUuid}/resources`}
                    className={styles.link}
                  >
                    {" "}
                    <Button className={styles.btn}>My Resources</Button>
                  </Link>
                  <Button
                    onClick={() => setIsPopupOpened(true)}
                    className={styles.btn}
                  >
                    Add Resource
                  </Button>
                </>
              )}
              <ResourceSearchFilter
                searchParamGroup={state.searchParamGroup}
                onReset={() => dispatch(resetFormFilters())}
                onSubmit={(values) => dispatch(setFormFilters(values))}
              />
              {/* <div className={styles.trendingBlock}>
                <p className={styles.caption}>Trending</p>
              </div> */}
            </div>
          </aside>
        </div>
      </div>
    </div>
  )
}
export default ResourceSearchContainer
