import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams, useHistory, Redirect } from "react-router"
import classNames from "classnames"
import Box from "@material-ui/core/Box"

import styles from "./SelectedUserProfileContainer.module.scss"
import UserInfo from "../../components/UserInfo/UserInfo"
import VideosPreviewer from "../../components/Videos/VideosPreviewer"
import ProfileTable from "../../components/ProfileTable/ProfileTable"
import userServices from "../../services/userServices"
import { selectInstitute } from "../IndividualProfileContainer/selectors"
import PhotoGalleryWrap from "../../components/PhotoGallery/PhotoGalleryWrap"
import UserResponseCard from "../../components/UserResponseCard/UserResponseCard"
import { paths } from "../../routes/paths"
import services from "../../services"
import Breadcrumbs from "../../components/elements/Breadcrumbs"
import useBreadcrumbs from "./useBreadcrumbs"
import Alert from "@material-ui/lab/Alert"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { ADMIN } from "../../helpers/userRoles"

const SelectedUserProfileContainer = () => {
  const [data, setData] = useState({})
  const userInfo = useSelector((state) => state.user)
  const breadcurmbsPaths = useBreadcrumbs(data)
  const [reportAlertVisible, setReportAlertVisible] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  const onBlock = (status) => {
    services.reportServices
      .blockUser({ user_uuid: data.uuid, block: status })
      .then(() => {
        setData({ ...data, blocked: status })
      })
      .catch(console.log)
  }

  const onReport = ({ title, description }) => {
    return services.reportServices
      .reportUser({
        title,
        description,
        reported_id: id,
        reported_type: "user",
      })
      .then((result) => {
        setReportAlertVisible(true)
        setData({ ...data, reported: true })
        setTimeout(() => setReportAlertVisible(false), 5000)
        return result
      })
  }

  useEffect(() => {
    userServices
      .getUser(id)
      .then((result) => {
        const schools = result.data.schools.map(selectInstitute)
        setData({ ...result.data, schools, videos: result.data.video_gallery })
      })
      .catch((e) => history.replace({ pathname: "/not-found", isActive: true }))
  }, [history, id])

  const onOpenChat = () => {
    if (!data.chat) {
      services.chatServices
        .createChatRoom({ type: "user", uuid: id })
        .then((result) => history.push(paths.chats(result.data.uuid)))
        .catch((error) => console.log(error))
    } else {
      history.push(paths.chats(data.chat))
    }
  }

  if (userInfo.uuid === id) {
    return <Redirect to={paths.individualProfile} />
  }

  if (data?.blocked && userInfo.role !== ADMIN) {
    return <Redirect to={paths.notFound} />
  }
  return (
    <div className={classNames("container", "fullWidth", styles.profileWrap)}>
      {reportAlertVisible && (
        <Alert
          variant="filled"
          severity="info"
          className={styles.alert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setReportAlertVisible(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Your report was successfully sent.
        </Alert>
      )}

      {userInfo.role !== ADMIN && (
        <Breadcrumbs classes={styles.breadcrumbs} chain={breadcurmbsPaths} />
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        style={{ width: "100%" }}
      >
        <div className={styles.profileLeftColumn}>
          <div className={styles.profileInfo}>
            <UserInfo
              describeMe={data["describe_me"]}
              activity={data["activity"]}
              gender={data["gender"]}
              race={data["race"]}
              fullName={data["full_name"]}
              image={data.photo}
              anotherUser
              onOpenChat={onOpenChat}
              onReport={onReport}
              reported={data.reported}
              userRole={userInfo.role}
              onBlock={onBlock}
              isBlocked={data.blocked}
            />
          </div>

          <UserResponseCard
            data={data.related_prospects}
            userInfo={{ uuid: data.uuid, name: data.full_name, type: "user" }}
          />

          <PhotoGalleryWrap
            className={classNames(styles.galleryWrap, styles.tablet)}
            type="preview"
            data={data["photo_gallery"]}
            editable={false}
            userType="users"
            userId={id}
          />
          <VideosPreviewer
            className={styles.videosWrap}
            videos={data["video_gallery"]}
            anotherUser={true}
            userId={id}
            userType="users"
          />
        </div>
        <div className={styles.profileRightColumn}>
          {data.photo_gallery?.length ? (
            <PhotoGalleryWrap
              className={styles.galleryWrap}
              type="preview"
              data={data.photo_gallery}
              editable={false}
              userId={id}
              userType="users"
            />
          ) : (
            ""
          )}
          <ProfileTable anotherUser data={data} />
        </div>
      </Box>
    </div>
  )
}

export default SelectedUserProfileContainer
