export const scrollOnError = (errors) => {
  try {
    setTimeout(() => {
      let errored
      if (Object.keys(errors).length) {
        errored = document.querySelectorAll("*")
        errored = [].slice.call(errored).filter(function (item) {
          if (item.classList)
            return (
              [].slice.call(item.classList).filter(function (item) {
                return item.indexOf("error") !== -1
              }).length !== 0
            )
          return false
        })
        errored[0] && errored[0].scrollIntoView(false)
      }
    })
  } catch (e) {
    console.log(e)
  }
}

export const PrivacyType = [
  {
    id: "01",
    value: "public",
  },
  {
    id: "02",
    value: "private",
  },
]

export const eventTypes = [
  {
    id: "01",
    value: "meeting",
  },
  {
    id: "02",
    value: "lunch",
  },
  {
    id: "03",
    value: "breakfast",
  },
  {
    id: "04",
    value: "webinar",
  },
  {
    id: "05",
    value: "virtual",
  },
]
