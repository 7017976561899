import React from "react"
import Dropdown from "../../../../Dropdown/Dropdown"
import { options } from "./options"
import { setActivityType } from "../../useReducer/actionCreators"
import useOption from "./useOption"

const ActivityTypeDropdown = ({ externalDispatch, roleType, selectedType }) => {
  const [option, setOption] = useOption(roleType, selectedType)
  return (
    <Dropdown
      label="Activity"
      id="activity_type_dropdown"
      options={options.filter((option) => option.role === roleType)}
      value={option}
      disabled={!roleType}
      onChange={(option) => {
        setOption(option)
        externalDispatch(setActivityType(option.id))
      }}
    />
  )
}
export default ActivityTypeDropdown
