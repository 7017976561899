export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE"
export const OPEN_INDIVIDUAL_SUBFORM = "OPEN_INDIVIDUAL_SUBFORM"
export const OPEN_COMPANY_SUBFORM = "OPEN_COMPANY_SUBFORM"
export const OPEN_FREELANCERS_SUBFORM = "OPEN_FREELANCERS_SUBFORM"
export const SET_WHOLE_STATE = "SET_WHOLE_STATE"
export const SET_JOB_TITLE = "SET_JOB_TITLE"
export const SET_JOB_TITLE_INPUT = "SET_JOB_TITLE_INPUT"
export const SET_LOCATION = "SET_LOCATION"
export const SET_LOCATION_INPUT = "SET_LOCATION_INPUT"
export const SET_ROLE_TYPE = "SET_ROLE_TYPE"
export const SET_ACTIVITY_TYPE = "SET_ACTIVITY_TYPE"
export const SET_GENDER_TYPE = "SET_GENDER_TYPE"
export const SET_RACE_TYPE = "SET_RACE_TYPE"
export const SET_COMPANY_TYPE = "SET_COMPANY_TYPE"
export const SET_LEVEL_TYPE = "SET_LEVEL_TYPE"
export const SET_COMPANY_ROLE_TYPE = "SET_COMPANY_ROLE_TYPE"
export const SET_COMPANY_ACTIVITY_TYPE = "SET_COMPANY_ACTIVITY_TYPE"
export const SET_COMPANY_INDUSTRY_TYPE = "SET_COMPANY_INDUSTRY_TYPE"
export const SET_COMPANY_DEPARTMENT_TYPE = "SET_COMPANY_DEPARTMENT_TYPE"
export const SET_COMPANY_DIVERSE_TYPE = "SET_COMPANY_DIVERSE_TYPE"
export const SET_COMPANY_CLASSIFICATION = "SET_COMPANY_CLASSIFICATION"
export const SET_COMPANY_CERTIFIED = "SET_COMPANY_CERTIFIED"
export const SET_COMPANY_MARKET = "SET_COMPANY_MARKET"
export const RESET_FORM = "RESET_FORM"
export const SET_SKILL = "SET_SKILL"
export const SET_SKILL_INPUT = "SET_SKILL_INPUT"
export const SET_CATEGORY_TYPE = "SET_CATEGORY_TYPE"
export const SET_FREELANCERS_COUNTRY = "SET_FREELANCERS_COUNTRY"

const initValues = {
  accountType: "individual",
  jobTitle: "",
  jobTitleInput: "",
  location: "",
  locationInput: "",
  skill: "",
  skillInput: "",
  roleType: "",
  activityType: "",
  genderType: "",
  raceType: "",
  companyType: "",
  companyRoleType: "",
  levelType: "",
  companyActivityType: "",
  companyIndustryType: "",
  companyDepartmentType: "",
  companyDiverseType: "",
  companyCertified: "",
  companyClassification: "",
  companyMarket: "",
  categoryType: "",
  freelancersCountry: ""
}
export const initState = {
  isCollapse: false,
  values: initValues,
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_COLLAPSE: {
      return { ...state, isCollapse: !state.isCollapse }
    }
    case OPEN_INDIVIDUAL_SUBFORM: {
      return {
        ...state,
        values: {
          ...state.values,
          accountType: "individual",
          jobTitle: "",
          jobTitleInput: "",
          location: "",
          locationInput: "",
          skill: "",
          skillInput: "",
          roleType: "",
          activityType: "",
          genderType: "",
          raceType: "",
          companyType: "",
          companyRoleType: "",
          levelType: "",
          companyActivityType: "",
          companyIndustryType: "",
          companyDepartmentType: "",
          companyDiverseType: "",
          companyCertified: "",
          companyClassification: "",
          companyMarket: "",
        },
      }
    }
    case OPEN_FREELANCERS_SUBFORM: {
      return {
        ...state,
        values: {
          ...state.values,
          accountType: "freelancers",
          jobTitle: "",
          jobTitleInput: "",
          location: "",
          locationInput: "",
          skill: "",
          skillInput: "",
          roleType: "",
          activityType: "",
          genderType: "",
          raceType: "",
          companyType: "",
          levelType: "",
          companyRoleType: "",
          companyActivityType: "",
          companyIndustryType: "",
          companyDepartmentType: "",
          companyDiverseType: "",
          companyCertified: "",
          companyClassification: "",
          companyMarket: "",
          categoryType: "",
          freelancersCountry: "",
        },
      }
    }
    case OPEN_COMPANY_SUBFORM: {
      return {
        ...state,
        values: {
          ...state.values,
          accountType: "company",
          jobTitle: "",
          jobTitleInput: "",
          location: "",
          locationInput: "",
          skill: "",
          skillInput: "",
          roleType: "",
          activityType: "",
          genderType: "",
          raceType: "",
          companyType: "",
          levelType: "",
          companyRoleType: "",
          companyActivityType: "",
          companyIndustryType: "",
          companyDepartmentType: "",
          companyDiverseType: "",
          companyCertified: "",
          companyClassification: "",
          companyMarket: "",
        },
      }
    }
    case SET_WHOLE_STATE: {
      const {
        requestResultGroup: {
          account_type,
          job_title,
          location,
          skill,
          describe_me,
          activity,
          gender,
          race,
          describe_company,
          industry,
          department,
          diverse,
          certified,
          classifications,
          markets,
          category,
          country,
          solopreneur,
          level_type
        },
      } = payload
      const values = {}
      if (account_type === "individual") {
        values.accountType = account_type ? account_type : ""
        values.jobTitle = job_title ? job_title : ""
        values.jobTitleInput = job_title ? job_title : ""
        values.location = location ? location : ""
        values.locationInput = location ? location : ""
        values.skill = skill ? skill : ""
        values.skillInput = skill ? skill : ""
        values.roleType = describe_me ? describe_me : ""
        values.activityType = activity ? activity : ""
        values.genderType = gender ? gender : ""
        values.raceType = race ? race : ""
      } else if (account_type === "freelancers") {
        values.accountType = account_type ? account_type : ""
        values.categoryType = category ? category : ""
        values.freelancersCountry = country ? country : ""
      } else {
        values.accountType = account_type ? account_type : ""
        values.companyRoleType = describe_company ? describe_company : ""
        values.companyType = solopreneur ? solopreneur : ""
        values.levelType = level_type ? level_type : ""
        values.companyActivityType = activity ? activity : ""
        values.companyIndustryType = industry ? industry : ""
        values.companyDepartmentType = department ? department : ""
        values.companyDiverseType = diverse ? diverse : ""
        values.companyCertified = certified ? certified : ""
        values.companyClassification = classifications ? classifications : ""
        values.companyMarket = markets ? markets : ""
      }
      return { ...state, values }
    }
    case SET_JOB_TITLE: {
      const { jobTitle } = payload
      return {
        ...state,
        values: { ...state.values, jobTitle, jobTitleInput: jobTitle },
      }
    }
    case SET_JOB_TITLE_INPUT: {
      const { jobTitleInput } = payload
      return {
        ...state,
        values: { ...state.values, jobTitle: "", jobTitleInput },
      }
    }
    case SET_LOCATION: {
      const { location } = payload
      return {
        ...state,
        values: { ...state.values, location, locationInput: location },
      }
    }
    case SET_LOCATION_INPUT: {
      const { locationInput } = payload
      return {
        ...state,
        values: { ...state.values, location: "", locationInput },
      }
    }
    case SET_SKILL: {
      const { skill } = payload
      return {
        ...state,
        values: { ...state.values, skill, skillInput: skill },
      }
    }
    case SET_SKILL_INPUT: {
      const { skillInput } = payload
      return {
        ...state,
        values: { ...state.values, skill: "", skillInput },
      }
    }
    case SET_ROLE_TYPE: {
      const { roleType } = payload
      return {
        ...state,
        values: { ...state.values, roleType, activityType: "" },
      }
    }
    case SET_ACTIVITY_TYPE: {
      const { activityType } = payload
      return { ...state, values: { ...state.values, activityType } }
    }
    case SET_GENDER_TYPE: {
      const { genderType } = payload
      return { ...state, values: { ...state.values, genderType } }
    }
    case SET_RACE_TYPE: {
      const { raceType } = payload
      return { ...state, values: { ...state.values, raceType } }
    }
    case SET_CATEGORY_TYPE: {
      const { categoryType } = payload
      return { ...state, values: { ...state.values, categoryType } }
    }
    case SET_FREELANCERS_COUNTRY: {
      const { freelancersCountry } = payload
      return { ...state, values: { ...state.values, freelancersCountry } }
    }
    case SET_COMPANY_TYPE: {
        const { companyType } = payload
        return { ...state, values: { ...state.values, companyType } }
    }
    case SET_LEVEL_TYPE: {
        const { levelType } = payload
        return { ...state, values: { ...state.values, levelType } }
    }
    case SET_COMPANY_ROLE_TYPE: {
      const { companyRoleType } = payload
      return {
        ...state,
        values: { ...state.values, companyRoleType, companyActivityType: "" },
      }
    }
    case SET_COMPANY_ACTIVITY_TYPE: {
      const { companyActivityType } = payload
      return { ...state, values: { ...state.values, companyActivityType } }
    }
    case SET_COMPANY_INDUSTRY_TYPE: {
      const { companyIndustryType } = payload
      return { ...state, values: { ...state.values, companyIndustryType } }
    }
    case SET_COMPANY_DEPARTMENT_TYPE: {
      const { companyDepartmentType } = payload
      return { ...state, values: { ...state.values, companyDepartmentType } }
    }
    case SET_COMPANY_DIVERSE_TYPE: {
      const { companyDiverseType } = payload
      return { ...state, values: { ...state.values, companyDiverseType } }
    }
    case SET_COMPANY_CERTIFIED: {
      const { companyCertified } = payload
      return { ...state, values: { ...state.values, companyCertified } }
    }
    case SET_COMPANY_CLASSIFICATION: {
      const { companyClassification } = payload
      return { ...state, values: { ...state.values, companyClassification } }
    }
    case SET_COMPANY_MARKET: {
      const { companyMarket } = payload
      return { ...state, values: { ...state.values, companyMarket } }
    }
    case RESET_FORM: {
      return {
        ...state,
        values: { ...initValues, accountType: state.values.accountType },
      }
    }
    default:
      throw new Error("Unknown action")
  }
}
