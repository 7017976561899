import { ReactComponent as Geo } from "../../../../../images/icons/geo.svg";
import { ReactComponent as Phone } from "../../../../../images/icons/phone.svg";
import { ReactComponent as Mail } from "../../../../../images/icons/MailDark.svg";
import { ReactComponent as Inst } from "../../../../../images/icons/InstagramRounded.svg";
import { ReactComponent as Facebook } from "../../../../../images/icons/FacebookRounded.svg";
import { ReactComponent as Linkedin } from "../../../../../images/icons/LinkedinRounded.svg";
import { ReactComponent as Twitter } from "../../../../../images/icons/TwitterRounded.svg";
import { ReactComponent as Public } from "../../../../../images/icons/public.svg";
import { ReactComponent as Group } from "../../../../../images/icons/group.svg";

function getIconAndPlaceholderByType(type) {
  switch (type) {
    case "address": {
      return {
        icon: Geo,
        placeholder: "Your address",
      };
    }
    case "company_name": {
      return {
        icon: Group,
        placeholder: "Your company name",
      };
    }
    case "phone": {
      return {
        icon: Phone,
        placeholder: "Your phone number",
      };
    }
    case "email": {
      return {
        icon: Mail,
        placeholder: "Your email",
      };
    }
    case "instagram": {
      return {
        icon: Inst,
        placeholder: "Your instagram link",
      };
    }
    case "twitter": {
      return {
        icon: Twitter,
        placeholder: "Your twitter link",
      };
    }
    case "facebook": {
      return {
        icon: Facebook,
        placeholder: "Your facebook link",
      };
    }
    case "linkedin": {
      return {
        icon: Linkedin,
        placeholder: "Your linkedin link",
      };
    }
    default: {
      return {
        icon: Public,
        placeholder: "New Entry",
      };
    }
  }
}

export default getIconAndPlaceholderByType;
