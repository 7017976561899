import React from "react"
import styles from "./ProfileTable.module.scss"
import GetMeKnow from "./components/GetMeKnow/GetMeKnow"
import TopFive from "./components/TopFive/TopFive"
import Skills from "./components/Skills/Skills"
import HigherLearningGroup from "./components/HigherLearningItem/HigherLearningGroup"
import MyStory from "./components/MyStory/MyStory"
import MyInspiration from "./components/MyInspiration/MyInspiration"
import JobTitle from "./components/JobTitle/JobTitle"

const ProfileTable = ({
  getToKnowUpdate,
  myStoryUpdate,
  myInspirationUpdate,
  myTopFiveUpdate,
  onUpdateSchools,
  data,
  anotherUser,
  onSkillsUpdate,
  jobTitleUpdate,
}) => {
  return (
    <div className={styles.profileTable}>
      <div className={styles.tableItem}>
        <JobTitle
          data={data["job_title"]}
          update={jobTitleUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <GetMeKnow
          data={data["get_to_know_me"]}
          getToKnowUpdate={getToKnowUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <HigherLearningGroup
          onUpdateSchools={onUpdateSchools}
          institutes={data["schools"]}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <MyStory
          data={data["my_story"]}
          update={myStoryUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <MyInspiration
          data={data["my_inspiration"]}
          update={myInspirationUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <TopFive
          data={data["top_five"]}
          update={myTopFiveUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <Skills
          anotherUser={anotherUser}
          data={data["skills"]}
          update={onSkillsUpdate}
        />
      </div>
    </div>
  )
}

export default ProfileTable
