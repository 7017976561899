export const options = [
  {
    id: "",
    value: "All",
  },
  {
    id: "female",
    value: "Female",
  },
  {
    id: "male",
    value: "Male",
  },
]
