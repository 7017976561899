import React from "react"
import { useHistory } from "react-router-dom"

import styles from "./index.module.scss"
import IndividualCard from "../../../components/cards/IndividualCard"
import { paths } from "../../../routes/paths"

const Users = ({ data, removeFromFavorites }) => {
  const history = useHistory()

  return (
    <div className={styles.listWrap}>
      {data.map((item) => (
        <div key={item.uuid} className={styles.cardWrapper}>
          <IndividualCard
            individualAvatar={item.photo}
            individualName={item.full_name}
            userUuid={item.uuid}
            chatUuid={item.chat}
            individualRole={item.describe_me}
            individualActivity={item.activity}
            description={item.skills}
            isOwned={false}
            onClick={() =>
              history.push(paths.selectedIndividualProfile(item.uuid))
            }
            onChatOpen={() => console.log("Open a chat")}
            removeFromFavorites={removeFromFavorites}
            isFavorite={item.favorite}
            gender={item.gender}
            race={item.race}
          />
        </div>
      ))}
    </div>
  )
}

export default Users
